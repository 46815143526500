@import '~@/styles/variables.module';

.title {
  font-size: 28px;
}

.platform_title {
  color: var(--primary);
}

.timer {
  font-size: 17px;
  font-weight: 500;
}

.info {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;

  box-sizing: border-box;

  //border: 2px solid rgba($base2, 0.4);

  min-width: 150px;

  //padding: 5px;
  padding: 7px 5px;
  text-align: center;

  color: var(--txt_2);
  border-radius: 4px;
  background: var(--surface_02);
}

.setting {
  font-weight: 700;
}

.online_operators {
  font-weight: 500;
  font-size: 28px;
  color: var(--txt_1);
  margin-left: 30px;

  &_number {
    color: var(--service-sussess);
  }
}
