

.container {
  min-height: 80vh;
  font-size: 16px;
  border-radius: 5px;
  background: var(--bg-widget);
}

.dataFilter {
 width: 100%;
}

.wrapper {
  width: 100%;
  margin-bottom: 33px;
}

.gridWrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.title {
  color: var(--txt_1);

  font-weight: 400;
  font-size: 24px;
  line-height: 136%;
}

.name {
  width: fit-content;
  min-width: 32%;

  text-align: end;
  margin-bottom: 8px;
  text-align: left;
}

.period {
  color: var(--add-txt_5);

  font-size: 20px;
}

.labelPeriod {
  color: var(--txt_1);

  font-size: 20px;
}

.item {
  font-size: 20px;
}
