@import '~@/styles/variables.module';

@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg)
  }
}

$progress-circular-rotate-animation: progress-circular-rotate 1.4s linear infinite;
$progress-circular-overlay-transition: all 0.6s ease-in-out;
$progress-circular-rotate-dash: progress-circular-dash 1.4s ease-in-out infinite;

.container {
  position: relative;

  display: inline-flex;

  vertical-align: middle;

  justify-content: center;
  align-items: center;
}

.figure {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  margin: auto;
}

.figure_indeterminate {
  transition: $progress-circular-overlay-transition;
  transform-origin: center center;
  animation: $progress-circular-rotate-animation;
}

.overlay {
  z-index: 2;

  transition: $progress-circular-overlay-transition;

  stroke: currentColor;
}

.overlay_indeterminate {
  animation: $progress-circular-rotate-dash;

  stroke-linecap: round;
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0;
}

.info {
  display: flex;

  align-items: center;
  justify-content: center;
}
