.icon {
  transition: var(--transition);
  flex: 0 0 auto;
}

/* color */
.icon_base0-stroke {
  stroke: var(--base0);
}

.icon_grey {
  fill: var(--grey);
}

.icon_green {
  fill: var(--green);
}

.icon_red {
  fill: var(--red);
}

.icon_base0-fill {
  fill: var(--base0);
}

.icon_secondary-fill {
  fill: var(--secondary-normal);
  opacity: 0.8;
}

.icon_base2-fill {
  fill: var(--base2);
}

.icon_base3-fill {
  fill: var(--secondary-normal);
}

.icon_blue-fill {
  fill: var(--primary);
}

/* Size */
.icon_size_8 {
  width: 8px;
  height: 8px;
}

.icon_size_10 {
  width: 10px;
  height: 10px;
}

.icon_size_15 {
  width: 15px;
  height: 15px;
}

.icon_size_20 {
  width: 20px;
  height: 20px;
}

.icon_size_24 {
  width: 24px;
  height: 24px;
}


.icon_size_25 {
  width: 25px;
  height: 25px;
}

.icon_size_30 {
  width: 30px;
  height: 30px;
}

.icon_size_40 {
  width: 40px;
  height: 40px;
}

.icon_mr_5 {
  margin-right: 5px;
}

.icon_mb_5 {
  margin-bottom: 5px;
}

.icon_mb_10 {
  margin-bottom: 10px;
}

.icon_mr_10 {
  margin-right: 10px;
}

.icon_mr_20 {
  margin-right: 20px;
}

.icon_rotate_180deg{
  transform: rotate(180deg)
}

.icon_mirror{
  transform: scale(1, -1);
}

.icon_trs_4{
  transform: translateY(4px)
}

.icon_anime_rotate_infinite {
  animation: rotate-infinite 1s linear infinite;
  position: relative;
  top: 5px;
}

.icon_anime_rotate {
  animation: rotate-infinite 1s linear infinite;
}

@keyframes rotate-infinite {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
