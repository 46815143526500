@import '~@/styles/variables.module';

.wrapper {
  margin: 70px;

  border-radius: 5px;
  background-color: $base4;
}

.inner {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
}

.title,
.info {
  margin: 0 auto;

  text-align: center;
}

.title {
  font-size: 24px;
  line-height: 30px;
}

.info {
  max-width: 900px;

  font-size: 16px;
  line-height: 24px;
}

.logo {
  text-align: center;
}
