.react-calendar {
  border: none;
  background: transparent;
  font-family: 'Roboto', Arial sans-serif;
  font-weight: 400;
}

.react-calendar__navigation {
  height: 40px;
  margin-bottom: 0;

  background: var(--bg-w-head);
}

.react-calendar__navigation .react-calendar__navigation button {
  flex-shrink: 0;
}

.react-calendar__navigation .react-calendar__navigation__label {
  width: 100%;
}

.react-calendar__navigation button {
  display: flex;

  align-items: center;
  justify-content: center;
  flex-shrink: 1;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--bg-w-head);
}

.react-calendar__tile--now {
  background: var(--surface_01);
}

.react-calendar__tile--active.react-calendar__tile--now {
  background: var(--primary_hover);
}


.react-calendar__navigation__label {
  color: var(--txt_widget);

  font-size: 16px;

  flex-shrink: 0;
}

.react-calendar__navigation__label:hover {
  cursor: pointer;

  opacity: 0.6;
}


.react-calendar__navigation__arrow {
  color: var(--icon_on_widget_header_normal);

  font-size: 24px;
}

.react-calendar__navigation__arrow:hover,
.react-calendar__navigation__arrow:focus {
  opacity: 0.4;
}



.react-calendar__month-view {
  box-sizing: border-box;
  padding: 10px;
}

.react-calendar__month-view__weekdays__weekday {
  text-transform: lowercase;

  color: var(--txt_2);

  font-weight: normal;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;

  font-size: 14px;
}

.react-calendar__month-view__weekdays__weekday:nth-child(6),
.react-calendar__month-view__weekdays__weekday:nth-child(7) {
  color: var(--add-txt_1);
}



.react-calendar__tile {
  box-sizing: border-box;
  padding: 5px;

  color: var(--txt_1);

  font-size: 16px;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  color: var(--on_primary);
  background: var(--primary_hover);
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: var(--surface_01);
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--primary_active);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--txt_3);
}

.react-calendar__tile:enabled:hover {
  color: var(--txt_1);
  background: var(--surface_01);
}


.react-calendar--selectRange .react-calendar__tile--hover {
  background: var(--primary_hover);
}

.react-calendar__tile--active,
.react-calendar__tile--hasActive {
  color: var(--on_primary);
  background: var(--primary_active);
}


.react-calendar__navigation__prev2-button {
  border-top-left-radius: 5px;
}

.react-calendar__navigation__next2-button {
  border-top-right-radius: 5px;
}

.react-calendar__navigation button[disabled] {
  color: var(--base0);
  background: transparent;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  box-sizing: border-box;
  padding: 10px;
}

.react-calendar__year-view__months,
.react-calendar__century-view,
.react-calendar__decade-view__years {
  background: var(--bg-widget);
}


.react-calendar__tile:disabled {
  color: var(--base2);
  background-color: transparent;
}

.calendar__data {
  margin: 0;
}
