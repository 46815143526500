@import '~@/styles/variables.module';

.cellWorst {
  //background: rgba(96, 47, 59, .7);
  background: var(--table_error_default);
}

.cellAverage {
  //background: rgba(182, 144, 6, .7);
  background: var(--table_warning_default);
}

.cellBest {
  //background: rgba(23, 74, 74, .7);
  background: var(--table_success_default);
}

