@import '~@/styles/variables.module';

.container {
  width: 100%;
  height: 100%;
}

.button {
  position: absolute;
  top: 50%;

  display: flex;

  width: 20px;
  height: 60px;
  padding: 0;

  cursor: pointer;
  transition: all 0.1s ease-out;
  transform: translateY(-50%);

  color: inherit;
  border: none;
  outline: none;
  background-color: rgba($grey, 0.4);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;

    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    width: 30px;

    background-color: $base8;
    box-shadow: 0 4px 20px rgba($base8, 0.2);
  };

  &_prev {
    left: 0px;

    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  };

  &_next {
    right: 0px;

    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  };
}

.icon {
  width: 32px;
  height: 32px;

  transition: all 0.1s ease-out;

  opacity: 0.3;
}

.button:hover:not(:disabled) > .icon {
  opacity: 1;
}
