@import '~@/styles/variables.module';

.button {
  padding: 0;

  cursor: pointer;
  transition: $transition;

  border: none;
  outline: none;
  background: transparent;
}