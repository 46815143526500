.personal {
  display: flex;
  margin: 0 25px;
  height: calc(100% - 64px);
}

.personal__container {
  border-radius: 5px;
  height: 100%;

  align-self: flex-start;
}

.personal__container_first {
  width: 60%;
  margin-right: 20px;
}

.personal__container_second {
  width: 40%;
  height: auto;
  align-self: inherit;
  display: flex;
  flex-direction: column;
}

.personal__section {
  color: var(--txt_1);
  margin-bottom: 40px;
  border-radius: 5px;
  background: var(--bg-widget);
}

.personal__section_nav {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  background-color: transparent;
}

.personal__section_nav button {
  flex: 0 1 auto;
}

.personal__section_nav button:first-child {
  /* width: 20%; */
}

.personal__section_nav button:not(:first-child) {
  /* width: 30%; */
  margin-left: 20px;
}

.personal__header {
  box-sizing: border-box;
  min-height: 61px;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: var(--bg-w-head);
}

.personal__content {
  display: flex;
  padding: 20px 30px;
}

.personal__content_second {
  display: block;
}

.personal__ava {
  max-width: 185px;
  margin-right: 20px;
}

.avatar__btn {
  width: 100%;
}

.personal__img-box {
  overflow: hidden;
  width: 185px;
  height: 185px;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.personal__img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

.personal__title {
  color: var(--txt_widget);
}

.personal__btn {
  width: 100%;
  padding: 0 10px;
  cursor: pointer;
  transition: var(--transition);
  color: var(--base0);
  border: 2px solid var(--blue);
  border-radius: 5px;
  background-color: var(--blue);
  font-size: 13px;
  line-height: 36px;
}

.personal__btn:hover,
.personal__btn:focus {
  background-color: transparent;
}

.personal__article:not(:last-child) {
  margin-bottom: 40px;
}

.personal__inputs-box {
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;
}

.personal__inputs-box > div {
  width: 47%;
}

.personal__date-container {
  margin: 40px 0;
}

.personal__date {
  display: flex;

  align-items: center;
  justify-content: space-between;
  position: relative;
}

.personal__date:not(:last-child) {
  margin-bottom: 20px;
}

.personal__date-text,
.personal__date-num {
  width: 47%;
}

.personal__date-num span {
  font-size: 14px;
  position: relative;
}

.personal__date-num span::before {
  position: absolute;
  height: 1px;
  bottom: -1px;
  left: 1px;
  right: 0;
  content: '';
  background-color: var(--base2);
}

.personal__date-num .input-info {
  width: 110px;
  margin: 0;
}

.personal__date-calendar {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.personal__arms {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.personal__arms-wrap {
  display: flex;
  margin: 10px 0 0 -20px;

  flex-wrap: wrap;
  pointer-events: inherit;
}

.personal__arms-wrap.disabled {
  pointer-events: none;
}

.personal__title_indent {
  color: var(--txt_1);
}

.personal__arms-btn {
  margin-top: 20px;

  align-self: flex-end;
}

.personal__inputs-pass {
  display: flex;
  flex-direction: column;
}

.personal__inputs-pass .input-info {
  width: 47%;
}

.personal__inputs-wrap {
  display: flex;

  justify-content: space-between;
}

.personal__btn-pass {
  margin-top: 40px;

  align-self: flex-end;
}
