@import '~@/styles/variables.module';

.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  align-items: center;
}
