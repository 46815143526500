.input-info {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  padding-top: 20px;
}

.input-info__field {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 0;
  color: var(--txt_1);
  border: none;
  border-bottom: 1px solid var(--stroke-default);
  outline: none;
  background-color: transparent;
  font-size: 15px;
  line-height: 32px;
}

.input-info__field::placeholder {
  color: var(--txt_1);
  font-size: 15px;
}

.input-info__label {
  position: absolute;
  top: 28px;
  left: 0;
  transition: var(--transition);
  white-space: nowrap;
  pointer-events: none;
  color: var(--txt_1);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.25;
}

.input-info.is-used .input-info__label {
  top: 5px;
  color: var(--txt_3);
  font-size: 13px;
}

.input-info__btn-default {
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.input-info__btn-default:hover {
  opacity: 1;
}

