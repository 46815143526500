@import '~@/styles/variables.module';

$duration: 300ms;

:export {
  duration: $duration;
}

.enter {
  right: -100%;
}

.enterActive {
  right: 0;
  transition: right $duration $easing-swing;
}

.exit {
  right: 0;
}

.exitActive {
  right: -100%;
  transition: right $duration $easing-swing;
}
