@import '~@/styles/variables.module';

@keyframes animate-dialog {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.03);
  }

  100% {
    transform: scale(1);
  }
}

.modal {
  position: relative;
  z-index: 100;
}

.content {
  display: flex;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;

  pointer-events: none;

  align-items: center;
  justify-content: center;
}

.inner {
  box-sizing: border-box;
  width: 100%;
  max-height: 90%;

  pointer-events: auto;

  &_animated {
    animation-name: animate-dialog;
    animation-duration: 0.15s;
    animation-timing-function: 'fast-in-fast-out';
  }

  &_scrollable {
    display: flex;
  }

  & > * {
    width: 100%;
  }
}
