.difference {
  margin-left: auto;

  &__small {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    text-align: center;
  }
}

.difference_name {
  font-weight: 400;
  font-size: 12px;
  line-height: 201%;
  margin-right: 5px;
  color: var(--txt_3);

  &__small {
    margin-right: 0;
    line-height: 12px;
  }
}

.difference_value {
  font-weight: 700;
  font-size: 24px;
  line-height: 137%;
  color: var(--txt_2);

  &__small {
    font-size: 18px;
    line-height: 21px;
  }
}
