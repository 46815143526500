@import '~@/styles/variables.module';

.title {
  font-size: 20px;
}

.wrapper {
  width: 100%;
  max-width: 30%;
}

.name {
  font-size: 14px;

  flex-shrink: 0;
}

.dashed {
  width: 100%;

  border-bottom: 1px dashed $base2;
}

.value {
  flex-shrink: 0;
}

.yellow {
  color: $yellow
}

.red {
  color: $active;
}

.green {
  color: $success;
}

.greenlight {
  color: $success-light;
}

.blue {
  color: $blue-light;
}

.darkblue {
  color: $main;
}

.black {
  color: $blackout;
}
