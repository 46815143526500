@import '~@/styles/variables.module';

.content {
  width: 100%;
  padding: 20px 0;

  border-top: 1px solid $base3;
  border-bottom: 1px solid $base3;
}

.header {
  width: 100%;
  margin-bottom: 10px;

  color: $success;

  font-size: 16px;
  font-weight: 500;
}

.text {
  color: $base0;

  font-size: 16px;
  line-height: 20px;
}

.subText {
  color: $yellow;
}

.link {
  text-decoration: none;

  color: $main;
}
