.content_wrapper {
  display: flex;
  min-height: 33px;
  align-items: center;
  margin-top: 5px;
}

.content {
  display: flex;
  color: var(--txt_5);

  &__forecast {
    margin-left: auto;
  }
}

.value {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;

  &__green {
    color: var(--service-sussess)
  }

  &__red {
    color: var(--service-error);
  }
}

.name {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: var(--txt_3);
  text-align: center;
}

.separator {
  width: 1px;
  height: 24px;
  background: var(--stroke-separator);
  margin-left: 16px;
  margin-right: 16px;
  align-self: center;
  position: relative;
  top: -2px;
}

.percent_wrapper {
  display: flex;
}

.forecast_icon {
  margin-left: 15px;
  flex-shrink: 0;
}
