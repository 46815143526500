@import '~@/styles/variables.module';

.title {
  font-size: 30px;
  line-height: 35px;
}

.titleHeader {
  color: var(--txt_1);
}

.partTitle {
  color: var(--primary);
}
