@import '~@/styles/variables.module';


.thumb {
  z-index: 110;

  cursor: pointer;
  transition: $transition;

  border-radius: 9999px;
  background: var(--surface_06);

  &:hover {
    background: var(--txt_primary);
  }
}

.noScroll::-webkit-scrollbar {  
  background: transparent;
}

.noScrollTable:-webkit-scrollbar { 
  width: 0;
}

.scrollbar {
  background: var(--bg);
}