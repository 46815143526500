@import '~@/styles/variables.module';

.layout {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.align_start {
  align-items: flex-start;
}

.align_center {
  align-items: center;
}

.align_end {
  align-items: flex-end;
}

.align_baseline {
  align-items: baseline;
}

.justify_center {
  justify-content: center;
}

.justify_between {
  justify-content: space-between;
}

.justify_around {
  justify-content: space-around;
}

.justify_end {
  justify-content: flex-end;
}

.direction_column {
  flex-direction: column;
}

.text_legend {
  font-size: 12px;
}
