@import '~@/styles/variables.module';

.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  touch-action: none;
}
