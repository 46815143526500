
@import '~@/styles/variables.module';
@import './colorsValue/dark';
@import './colorsValue/dark_default';
@import './colorsValue/dark_color';
@import './colorsValue/light';
@import './colorsValue/light_default';
@import './colorsValue/light_color';
@import './components/icons.scss';
@import './components/buttons.scss';
@import './components/text.scss';
@import './components/graphic.scss';


/* тут пишем общие стили к которым подключаем переменные из папки стилей colorsValue */
.body {
  color: var(--app-color);
  background: $bgDefault;
}

.bg {
  background: var(--bg);
}

.bg_w_head {
  background: var(--bg-w-head);
}

.bg_widget {
  background: var(--bg-widget);
}

.bg_menu_01 {
  background: var(--bg-menu_01);
}

.bg_menu_02 {
  background: var(--bg-menu_02);
}

.bg_close {
  background: var(--primary);
}

/* блок маленьких виджетов */

.block_small_widget {
  //background: var(--bg-widget);
  //box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
}


