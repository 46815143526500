@import '~@/styles/variables.module';

.container {
  //padding: 10px;
  padding-bottom: 10px;

  border-radius: 5px;

  &__mini_brief {
    padding-bottom: 0;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(352px, 1fr));
  gap: 20px;

  &__mini_brief {
    grid-template-columns: repeat(auto-fill, minmax(344px, 1fr));
  }
}

.dark {
  background: $base4;
}

.light {
  background: $bg-light-body;
}
