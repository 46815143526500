.txt_1 {
  color: var(--txt_1)
}

.txt_2 {
  color: var(--txt_2)
}

.txt_3 {
  color: var(--txt_3)
}

.txt_4 {
  color: var(--txt_4)
}

.txtLink {
  color: var(--txt_link)
}

.txtWidget {
  color: var(--txt_widget)
}

.txtTertiary {
  color: var(--txt_tertiary)
}

.txtPrimary {
  color: var(--txt_primary);
}
