@import '~@/styles/variables.module';

.header {
  color: var(--txt_1);

  font-size: 16px;
  font-weight: 500;
}

.frame {
  padding: 10px;

  border-radius: 5px;
  background: var(--surface_01);

  font-size: 16px;
  font-weight: 500;
}

.text {
  position: relative;

  cursor: pointer
}

.text_money {
  color: var(--service-sussess);
}

.text_coefficient {
  color: var(--add-txt_5);
}

.text_time {
  color: var(--add-txt_4);
}

.tooltip {
  max-width: 300px;

  white-space: pre-wrap;
}
