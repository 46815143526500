.container {
  padding-bottom: 0;

  border-radius: 5px;
}

.grid {
  display: grid;

  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(344px, 1fr));
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  min-height: 105px;
  padding: 10px 16px;

  transition: background 0.3s ease-in-out;

  border-radius: 8px;
  background: var(--bg-mini);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);

  justify-content: space-between;

  &:hover {
    background: linear-gradient(0deg, rgba(var(--primary_rgb), 0.1), rgba(var(--primary_rgb), 0.1)) var(--bg-mini);
  }
  &:active {
    background: linear-gradient(0deg, rgba(var(--primary_rgb), 0.2), rgba(var(--primary_rgb), 0.2)) var(--bg-mini);
  }
}

.header {
  display: flex;

  justify-content: space-between;
  align-items: flex-start;
}


.header_title {
  letter-spacing: 0.01em;

  color: var(--txt_1);

  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}

.linkMiniBrief {
  text-decoration: none;
}

.selectedMiniWidget {
  background: linear-gradient(0deg, rgba(var(--primary_rgb), 0.1), rgba(var(--primary_rgb), 0.1)) var(--bg-mini);
}
