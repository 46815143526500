@import '~@/styles/variables.module';

$duration: 200ms;

:export {
  duration: $duration;
}

.enter {
  transform: translateX(100%);
}

.enterActive {
  transform: translateX(0%);
}

.exit {
  transform: translateX(0%);
}

.exitActive {
  transform: translateX(-100%);
}

.enterActive,
.exitActive {
  transition: transform $duration;
}
