.wrapper {
  display: flex;

  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
}

.wrapper_column {
  flex-direction: column;
}

.wrapper_nocenter {
  align-items: normal;
}