.saturation {
  position: relative;

  width: 216px;
  margin-right: 10px;
}

.hue {
  position: relative;

  width: 39px;
}

.hue,
.saturation {
  box-sizing: border-box;

  border: 1px solid var(--base6-slick);
}


.pointer {
  width: 39px;
  height: 3px;

  cursor: pointer;

  opacity: 0.8;
  background-color: var(--base0);
}

.picker {
  display: flex;
}

.inputs {
  margin-left: 11px;

  color: var(--base2-04);
}
