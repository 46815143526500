/* иконки */
.iconPrimary {
  transition: color 0.3s ease-in-out;

  color: var(--icon_primary_normal);

  &:hover {
    color: var(--icon_primary_hover);
  }

  &_active {
    color: var(--icon_primary_active);
  }

  &:disabled {
    color: var(--icon_primary_disable);
  }
}

.iconOpacity {
  transition: color 0.3s ease-in-out;

  color: var(--icon_primary_normal);
  &:hover {
    opacity: 0.6;
  }
  &_active {
    color: var(--icon_primary_active);
  }

  &:disabled {
    color: var(--icon_primary_disable);
  }
}

.iconSecondary {
  transition: color 0.3s ease-in-out;

  color: var(--icon_secondary_normal);

  &:hover {
    color: var(--icon_secondary_hover);
  }

  &_active {
    color: var(--icon_secondary_active);
  }

  &:disabled {
    color: var(--icon_secondary_disable);
  }
}

.iconTertiary {
  transition: color 0.3s ease-in-out;

  color: var(--icon_tertiary_normal);

  &:hover {
    color: var(--icon_tertiary_hover);
  }

  &_active {
    color: var(--icon_tertiary_active);
  }

  &:disabled {
    color: var(--icon_tertiary_disable);
  }
}

.iconOnPrimary {
  color: var(--icon_on_primary);
}

.iconOnSecondary {
  color: var(--icon_on_secondary);
}

.iconOnTertiary {
  color: var(--icon_on_tertiary);
}

.iconOnMenu {
  transition: color 0.3s ease-in-out;

  color: var(--icon_on_menu_normal);

  &:hover {
    color: var(--icon_on_menu_hover);
  }

  &_active {
    color: var(--icon_on_menu_active);
  }

  &:disabled {
    color: var(--icon_on_menu_disable);
  }
}

.iconOnWidgetHeader {
  transition: color 0.3s ease-in-out;

  color: var(--icon_on_widget_header_normal);

  &:hover {
    color: var(--icon_on_widget_header_hover);
  }

  &_active {
    color: var(--icon_on_widget_header_active);
  }

  &:disabled {
    color: var(--icon_on_widget_header_disable);
  }
}

.iconDefault {
  transition: color 0.3s ease-in-out;

  color: var(--icon_default_normal);

  &:hover {
    color: var(--icon_default_hover);
  }

  &_active {
    color: var(--icon_default_active);
  }

  &:disabled {
    color: var(--icon_default_disable);
  }
}

/* select */

.iconColor {
  transition: color 0.3s ease-in-out;

  color: var(--icon_secondary_normal);

  &:hover {
    color: var(--service-error);
  }

  &_active {
    color: var(--icon_secondary_active);
  }

  &:disabled {
    color: var(--icon_secondary_disable);
  }
}
