@import '~@/styles/variables.module';

.title {
  font-size: 18px;
  line-height: 21px;
}

.button {
  padding: 0;

  cursor: pointer;
  transition: $transition;

  color: $base2;
  border: none;
  outline: none;
  background: transparent;

  &:hover {
    color: $base0;
  }
}

.themesIcons {
  max-height: 64px;

  border: 2px solid var(--secondary-normal);
  border-radius: 50%;
}

.activeTheme {
  outline: 2px solid var(--stroke-active)
}
