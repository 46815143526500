/*sales Table*/
.salesTable {
  width: 100%;
  max-width: calc(100% - 20px);
  margin: 20px auto;
  padding: 20px 0;

  white-space: nowrap;

  border-radius: 5px;
  /*background-color: #2a3243;*/
  background: var(--bg-widget);
  backface-visibility: hidden;
  color: var(--txt_1)
}


.salesTable_margin {
  margin-top: 40px;
}

.salesTable__title {
  margin-bottom: 20px;
  margin-left: 20px;

  font-size: 30px;
}

.salesTable__title span {
  color: var(--blue);
}

.salesTable__scroll {
  overflow-x: auto;
}

.salesTable table {
  width: 100%;

  border-spacing: 0;

  border: 1px solid var(--stroke-default);
  border-collapse: separate;
  border-radius: 5px;
}

/* .salesTable table thead {
  border: 1px solid rgba(144, 144, 144, 0.4);
} */

.salesTable table tbody .salesTable__row.salesTable__row_first td {
  background-color: var(--green-slick);
}

.salesTable table tbody .salesTable__row.salesTable__row_second td {
  background-color: var(--red-slick);
}

.salesTable table tbody .salesTable__row.salesTable__row_third td {
  background-color: var(--base8-slick);
}

.salesTable table thead .salesTable__row.salesTable__row_fourth th {
  background-color: var(--base6);
}

.salesTable table thead tr th {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  top: 0;

  box-sizing: border-box;
  padding: 5px;

  vertical-align: middle;
  white-space: nowrap;

  border-right: 1px solid var(--stroke-default);
  border-bottom: 1px solid var(--stroke-default);
  background: #1f2839;

  font-size: 14px;

  backdrop-filter: blur(10px);
}

.salesTable table thead tr:nth-child(2) th {
  top: calc(30px + 2px);
}

.salesTable table thead tr:nth-child(3) th {
  top: calc(30px + 2px);
}

.salesTable table thead th:last-child {
  border-right: 0;
}

.salesTable table .blue-text {
  color: var(--blue);
}

.salesTable table .green-text {
  color: var(--green);
}

.salesTable table .red-text {
  color: var(--red);
}

.salesTable table tbody td {
  /* padding: 5px; */
  vertical-align: middle;
  /* text-align: center; */

  border-right: 1px solid var(--stroke-default);
  border-bottom: 1px solid var(--stroke-default);
  /*background: #1f2839;*/
  background: var(--table_default);
  font-size: 13px;
}

.salesTable table tbody tr:nth-child(2n) td {
  /* background: rgba(95, 101, 115, 0.2); */
}

.salesTable table tbody tr:first-child th:first-child {
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;

  text-align: left;
  /* background: #1F2839 !important; */
}

.salesTable table tbody td:last-child {
  border-right: 0;
}

.salesTable table tbody td.title-block {
  /* background: #565E6C !important; */
}

.salesTable table tbody td:first-child {
  position: sticky;
  z-index: 4;
  left: 0;
}

.salesTable table thead tr:first-child th:first-child {
  position: sticky;
  z-index: 6;
  top: 0;
  left: 0;
}

.salesTable table tfoot tr td:first-child {
  position: sticky;
  z-index: 7;
  bottom: 0;
  left: 0;
}

.salesTable table tfoot tr td {
  position: sticky;
  z-index: 6;
  bottom: 0;
}



/*.salesTable__table-thead {*/
/*  background: #1F2839;*/
/*}*/

/*.salesTable__table-thead thead tr th  {*/
/*  background: #1F2839;*/
/*}*/
