.sort-button {
  width: 40px;

  border: none;
  border-left: 1px solid rgba(144, 144, 144, 0.4);
  background: transparent;
}
.sort-button__sorted .icon {
  fill: var(--base0);
}
.sort-button__reverse .icon {
  transform: rotate(0);

  fill: var(--blue);
}
