@import '~@/styles/variables.module';
$screen-md: 1194px;

.container {
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;

  box-sizing: border-box;
  width: 346px;
  height: 100%;
  padding: 20px;
  padding-top: 35px;

  background: var(--bg-menu_02);

  @media (max-width: $screen-md) {
    //  width: 320px;
    padding: 5px 15px;
  }


  &::-webkit-scrollbar {
    width: 0;

    background: transparent;
  }
}

.profile {
  flex: 1;
}

.bio_button {
  position: absolute;
  z-index: 2;
  top: 70px;
  right: 130px;

  display: flex;

  cursor: pointer;
  transition: background 0.2s ease-in-out;
  text-align: center;

  color: var(--secondary-onSecondary);
  border-radius: 50%;
  background: var(--secondary-normal);

  align-items: center;
}

.bio_button:hover {
  background: var(--secondary-hover);
}

.position {
  text-align: center;

  color: var(--primary);

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: $screen-md) {
    margin: 0 0 20px 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
