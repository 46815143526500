@import '~@/styles/variables.module';

.input {
  box-sizing: border-box;
  min-height: 36px;

  transition: $transition;

  color: var(--txt_1);
  border: none;
  border-color: var(--stroke-default);
  border-bottom: 1px solid var(--stroke-default);
  border-radius: initial;
  background: transparent;
  box-shadow: none;

  caret-color: var(--stroke-active);

  &:focus {
    outline: none;
  }
}

.input:-webkit-autofill {
  box-shadow: inset 0 0 0 50px $base4;

  -webkit-text-fill-color: $base0;
}

.input_focused {
  border-bottom: 1px solid var(--stroke-active);
}

.input_error {
  border-bottom: 1px solid $error;
}

.input_hide_number_arrows::-webkit-outer-spin-button,
.input_hide_number_arrows::-webkit-inner-spin-button {
  margin: 0;

  -webkit-appearance: none;
}

.input_hide_number_arrows[type=number] {
  -moz-appearance: textfield;
}

.pos_relative {
  position: relative;
}

.icon {
  transition: $transition;

  color: var(--txt_3);
}

.icon_inner {
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
}

.icon_inner_left {
  left: 0;
}

.icon_inner_right {
  right: 0;
}

.progress {
  position: absolute;
  top: 50%;
  right: 0;

  transform: translateY(-50%);
}

.progress_icon {
  right: 32px;
}
