@import '~@/styles/variables.module';

.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  user-select: none;
}

.text_box {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  fill: rgba($base9, 0.9);
}

.text_label {
  font-size: 12px;
}

.textTotal {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--txt_2);
}
