@import '~@/styles/variables.module';


//переменные для стрелки
$directionUp: translateY(50%) rotate(90deg) !important;
$directionDown: translateY(50%) rotate(-90deg) !important;
$directionRight: translateX(-50%) rotate(180deg) !important;

.wrapper .container :global(.rc-slider-track) {
  background: var(--primary_hover);
}

.wrapper .containerVertical :global(.rc-slider-handle-1),
.wrapper .containerVertical :global(.rc-slider-handle-2),
.wrapper .containerHorizontal :global(.rc-slider-handle-1),
.wrapper .containerHorizontal :global(.rc-slider-handle-2) {
  background: var(--primary_hover);
}

.wrapper .containerVertical {
  :global(.rc-slider-handle-1) {
    transform: $directionDown;
  }
  :global(.rc-slider-handle-2) {
    transform: $directionUp;
  }
}

.wrapper .containerHorizontal {
  :global(.rc-slider-handle-2) {
    transform: $directionRight;
  }
}

.container {
  :global(.rc-slider-rail) {
    background: var(--bg);
  }
  :global(.rc-slider-track) {
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    background: var(--surface_06);
  }
  :global(.rc-slider-handle) {
    width: 16px;
    height: 16px;

    border: 0;
    background-color: transparent;

    & svg {
      pointer-events: none;
    }
  }

  :global(.rc-slider-handle.rc-slider-handle-dragging) {
    border-color: transparent;
    box-shadow: none;
  }
}

.containerHorizontal {
  width: auto;
  max-width: 100%;

  :global(.rc-slider-rail),
  :global(.rc-slider-track) {
    height: 8px;

    border-radius: 4px;
  }

  :global(.rc-slider-handle) {
    margin-top: -4px;
  }

  :global(.rc-slider-handle-1),
  :global(.rc-slider-handle-2) {
    transition: background 0.3s ease-in-out;
    background: var(--surface_06);
  }

  :global(.rc-slider-handle-2) {
    transform: $directionRight;
  }
}

.containerVertical {
  :global(.rc-slider-rail),
  :global(.rc-slider-track) {
    width: 8px;

    border-radius: 4px;
  }

  :global(.rc-slider-handle) {
    margin-left: -4px;
  }
  :global(.rc-slider-handle-1),
  :global(.rc-slider-handle-2) {
    transition: background 0.3s ease-in-out;
    background: var(--surface_06);
  }
  :global(.rc-slider-handle-1) {
    transform: $directionDown;
  }

  :global(.rc-slider-handle-2) {
    transform: $directionUp;
  }
}

.container:hover {
  :global(.rc-slider-track) {
    background: var(--primary_hover);
  }
}

.containerHorizontal:hover {
  :global(.rc-slider-handle-1),
  :global(.rc-slider-handle-2) {
    background: var(--primary_hover);
  }

  :global(.rc-slider-handle-2) {
    transform: $directionRight;
  }
}

.containerVertical:hover {
  :global(.rc-slider-handle-1),
  :global(.rc-slider-handle-2) {
    background: var(--primary_hover);
  }
  :global(.rc-slider-handle-1) {
    transform: $directionDown;
  }

  :global(.rc-slider-handle-2) {
    transform: $directionUp;
  }
}
