@import './variables.module';

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-vertical-middle {
  vertical-align: middle;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.text-no-wrap {
  white-space: nowrap;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-success {
  color: $success;
}

.text-info {
  color: var(--txt_primary);
}

.text-warning {
  color: $warning;
}

.text-error {
  color: $error;
}
