@import '~@/styles/variables.module';

.tooltip {
  position: absolute;

  display: inline-block;

  padding: 5px;

  white-space: pre-wrap;

  color: #fff;
  border-radius: 5px;
  background: var(--surface_05);
}
