@import '~@/styles/variables.module';

.container {
  box-sizing: border-box;
  width: fit-content;

  border-radius: 5px 5px 0 0;
  background: var(--bg-widget);

  &_hasHorisontalScroll {
    padding-bottom: 8px;
  }
}

.item {
  position: relative;

  margin: 0;
  padding: 25px 40px;

  list-style: none;
  :hover {
    color: var(--txt_1);
  }
}

.item:not(:first-child)::before {
  position: absolute;
  top: 50%;
  left: 0;

  width: 1px;
  height: 43px;

  content: '';
  transform: translateY(-50%);

  background: var(--stroke-separator);
}

.item::after {
  position: absolute;
  bottom: 0;
  left: 50%;

  width: 0;
  height: 4px;

  content: '';
  transition: width 100ms ease-out;
  transform: translateX(-50%);

  background: var(--primary);
}

.item_active::after {
  width: calc(100% - 40px);
}

.link {
  display: inline;

  cursor: pointer;
  user-select: none;
  transition: all 100ms ease-out;
  text-decoration: none;

  color: var(--txt_2);
  border: none;
  outline: none;
  background: transparent;

  font-size: 15px;
  line-height: 18px;
}

.link_active {
  color: var(--txt_1);
}
