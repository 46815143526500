.textY {
  color: var(--txt_2);
}

.textX {
  color: var(--txt_link);
  font-size: 0.8em;
}

.textTooltip {
  fill: #fff;
}

.rect {
  fill: var(--surface_05);
}

.barBg{
  fill: var(--surface_04);
}
