@import '~@/styles/variables.module.scss';

.form {
  position: relative;
  display: flex;
  width: max-content;

  align-items: center;
}

.input {
  box-sizing: border-box;
  width: 100%;
  max-width: 100px;
  height: 36px;
  padding: 3px 40px 3px 10px;
  color: $base0;
  border: 1px solid $main;
  border-radius: 5px;
  background-color: transparent;
  font-size: 13px;
}

.button {
  position: absolute;
  right: 3px;
  width: 30px;
  height: 30px;
  color: $base0;
  border: none;
  border-radius: 3px;
  background-color: $main;

  &:hover {
    cursor: pointer;
  }
}

.number_page {
  margin-left: 10px;
  color: $base0;
  font-size: 13px;
}
