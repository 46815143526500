.title {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.container,
.wrapper {
  height: 100%;
}

.wrapper {
  width: 50%;
}

.wrapDot {
  margin-top: 7px;
}

.dot {
  width: 8px;
  height: 8px;

  border-radius: 100%;
}

.value {
  font-weight: 700;
}
