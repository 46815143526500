@import '~@/styles/variables.module';

.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  align-items: stretch;
}

.image {
  flex: 1 1 auto;
}

.footer {
  flex: 0 0 auto;
}

.dot {
  width: 8px;
  height: 8px;

  border-radius: 9999px;
}

.title {
  flex: 0 0 auto;

  text-align: center;

  font-size: 18px;
  line-height: 20px;
}
