@import '~@/styles/variables.module';

.head {
  border-radius: 5px 5px 0 0;
  background: var(--surface_01);

  font-size: 18px;
  line-height: 35px;
}

.body {
  border-radius: 0 0 5px 5px;
  background: var(--bg-widget);
}

.wrapper {
  max-width: 300px;
}
