@import '~@/styles/variables.module';

.frame {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0px;
  border-radius: 5px;
}

.full_height {
  height: 100%;
}

.frame_bordered {
  padding: 5px;
}

.content_bordered {
  padding: 10px;

  border: 1px solid rgba($grey, 0.8);
  border-radius: 5px;
}
