@import '~@/styles/variables.module';

.styled {
  position: relative;

  display: flex;
  flex-direction: column;

  height: 100%;
  padding-left: 60px;

  &_pr {
    padding-right: 346px
  }
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  width: 100%;
  background: var(--bg);
}

.column {
  display: flex;

  align-items: center;
  justify-content: center;
}

.label {
  font-size: 17px;
  font-weight: 500;
}


