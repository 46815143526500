@import '~@/styles/variables.module';

.container {
  position: relative;
  z-index: 100 ;

  display: flex;

  align-items: center;
}

.select {
  width: 150px;
}

