@import '~@/styles/variables.module';

$screen-md: 1194px;

.avatar {
  position: relative;
  z-index: 2;

  overflow: hidden;

  box-sizing: border-box;
  width: 60px;
  height: 60px;
  padding: 5px;

  transition: $transition;

  border-radius: 100px;
  box-shadow: 0 0 0 2px var(--tertiary);

  object-fit: cover;
  flex-shrink: 0;

  @media (max-width: $screen-md) {
    width: 90px;
    height: 90px;
  }
}
