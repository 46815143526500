@import '~@/styles/variables.module';

.default {
  color: rgba(255, 255, 255, 0.2);

  span {
    color: $base0;
  }
}

.info {
  transition: opacity 0.3s ease-in-out;

  color: var(--primary);
  
  &:hover {
    opacity: 0.6;
  }
}

.success {
  color: var(--service-sussess);
}

.warning {
  color: var(--service-warning);
}

.error {
  color: var(--service-error);
}

.gray {
  transition: color 0.2s ease-in-out;

  color: var(--secondary-normal);

  &:hover {
    color: rgba(var(--primary_rgb), 0.6);
  }
}

.hoverable {
  cursor: pointer;
}

.notEventable {
  pointer-events: none;
}
