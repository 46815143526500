@import '~@/styles/variables.module';

.text_x {
  font-size: 14px;
}

.container {
  .grid_line {
    stroke: var(--secondary-normal);
    shape-rendering: crispedges;
    //stroke-opacity: 0.2;
  }
}

.link {
  cursor: pointer;

  font-size: 14px;
}

.percent {
  font-size: 16px;
  font-weight: bold;
}

.text_bars {
  font-size: 1.5em;
  font-weight: bold;
}

.barsBg {
  fill: var(--surface_04);
}
