.slider-first {
  position: absolute;
  z-index: 1000;
  top: 10%;
  left: 50%;

  box-sizing: border-box;
  width: 60%;
  max-width: 856px;
  margin: 0 auto;
  padding: 60px 70px;
  filter: var(--shadow_2);
  transform: translateX(-50%);

  border-radius: 20px;
  background: var(--bg-widget);
}

.slider-first__nav {
  position: absolute;
  top: 0;
  bottom: 0;

  display: flex;

  width: 70px;

  cursor: pointer;

  border: none;
  outline: none;
  background: var(--secondary-normal);

  align-items: center;
  justify-content: center;
}

.slider-first__nav:hover {
  background: var(--secondary-hover);
}
.slider-first__nav-arrow {
  color: var(--secondary-normal);
  opacity: 0.8;
}
.slider-first__nav-arrow:hover {
  opacity: 1;
}

.slider-first__nav_prev {
  left: 0;

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.slider-first__nav_next {
  right: 0;

  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.slider-first .swiper-container {
  width: 460px;
  padding: 0 20px;
}

.slider-first__thumb .swiper-wrapper {
  /* margin-left: calc(-50% + 50px); */
  transform: none !important;
}

.slider-first__main .swiper-slide,
.slider-first__thumb .swiper-slide {
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.slider-first__main .swiper-slide {
  height: 460px;
}

.slider-first__thumb .swiper-slide {
  position: relative;

  box-sizing: border-box;
  width: 25%;
  height: 100px;

  cursor: pointer;
}

.slider-first__thumb .swiper-container {
  padding-top: 20px;
}

.slider-first__thumb .swiper-slide::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
  transition: var(--transition);

  opacity: 0;
  border: 2px solid var(--primary);
  border-radius: 5px;
}

.slider-first__thumb .swiper-slide:hover::before {
  opacity: 1;
}

.slider-first__thumb .swiper-slide.swiper-slide-active::before {
  opacity: 1;
}

.slider-first__del-img {
  position: absolute;
  z-index: -1;
  top: -11px;
  right: -11px;

  display: flex;

  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;

  cursor: pointer;
  transition: var(--transition);
  pointer-events: none;

  opacity: 0;
  border: none;
  border: 1px solid transparent;
  border-radius: 50%;
  outline: none;
  background: var(--primary);
  align-items: center;
  justify-content: center;
  color:var(--on_primary);
}

.slider-first__thumb .swiper-slide.swiper-slide-active .slider-first__del-img {
  z-index: 10;

  pointer-events: initial;

  opacity: 1;
}

.slider-first__thumb .swiper-slide.swiper-slide-active .slider-first__del-img:hover {
  opacity: 0.9;
}

.slider-first__img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

.slider-first__bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  min-height: 72px;

  background: var(--bg-widget);
  opacity: 0.8;
  justify-content: center;
  align-items: center;
}

.slider-first__btn {
  display: flex;

  box-sizing: border-box;
  width: 190px;
  height: 30px;
  padding: 0;

  cursor: pointer;
  transition: var(--transition);

  color: var(--txt_2);
  border: none;
  background-color: transparent;

  align-items: center;
}

.slider-first__btn:hover {
  color: var(--txt_1);
}

.slider-first__btn .icon {
  margin-right: 10px;
}

.slider-first__btn:hover .icon {
  fill: var(--blue);
}

.slider-first__btn label {
  display: flex;

  height: 30px;

  cursor: pointer;

  align-items: center;
  justify-content: space-between;
}

.slider-first__wrap-btn {
  text-align: center;
  margin-top: 10px;
}

.slider-first__close {
  position: absolute;
  top: 10px;
  right: 15px;

  display: flex;

  padding: 0;

  cursor: pointer;

  border: none;
  outline: none;
  background-color: transparent;
  color: var(--primary);
}

.slider-first__close:hover {
  opacity: 0.8;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  transition: opacity 500ms;

  opacity: 1;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  transition: opacity 500ms;

  opacity: 0;
}

.slider-first__overlay {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
  transition: var(--transition);

  opacity: 0;
  background-color: rgba(0,0,0,0.6);
}

.slider-first__overlay.is-opened {
  opacity: 1;
}
