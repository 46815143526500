@import '~@/styles/variables.module';

.title {
  font-size: 24px;
  line-height: 120%;
}

.list {
  font-size: 16px;
}

.item {
  margin-bottom: 15px;

  list-style: decimal;
}
