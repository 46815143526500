@import '~@/styles/variables.module';

.list {
  border-radius: 5px;
  background: var(--surface_01);
  margin: 0;
  padding: 0;
}

.item {
  margin: 0;
  color: var(--txt_1);
  border-bottom: 1px solid var(--stroke-separator);

  font-size: 14px;
  line-height: 120%;
  font-family: "Roboto", sans-serif;
}
