@import '~@/styles/variables.module';

.menu {
  position: relative;
}

.menuButton {
  display: flex;

  width: 10px;
  height: 30px;
  margin: 0;
  padding: 0;

  cursor: pointer;

  color: $main;
  border: 0;
  outline: none;
  background: transparent;

  align-items: center;
  justify-content: center;
}

.menuList {
  &:before {
    position: absolute;
    top: -5px;
    right: 5px;

    box-sizing: border-box;
    width: 5px;
    height: 5px;

    content: '';
    transform: translateY(-50%);

    border-top: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba($grey, 0.8);
    border-left: 5px solid transparent;
  };
}

.menuNav {
  position: absolute;
  z-index: 2;
  top: calc(100% + 10px);
  right: -5px;

  padding: 10px 0;

  border-radius: 5px;
  background: $grey;
}

@supports (backdrop-filter: blur(30px) saturate(150%)) {
  .menuNav {
    background: rgba($grey, 0.8);

    backdrop-filter: blur(30px) saturate(150%);
  }
}

.menuLink {
  position: relative;

  display: flex;
  overflow: hidden;

  box-sizing: border-box;
  width: 100%;
  padding: 5px 15px;

  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;

  color: $base0;

  font-size: 14px;
  line-height: 1;

  align-items: flex-start;
  justify-content: flex-start;

  &:before {
    position: absolute;
    top: 0;
    left: 0;

    width: 3px;
    height: 100%;

    content: '';

    opacity: 0;
    background: $base0;
  };

  &:hover {
    color: $base0;
    background: $main;
  };

  &:hover::before {
    opacity: 1;
  }
}

@keyframes slide {
  from {
    transform: translateY(10px);

    opacity: 0;
  }

  to {
    transform: translateY(0);

    opacity: 1;
  }
}

.menuButton[open] .menu {
  animation: slide 0.3s ease-in-out;
}

.menuButton::-webkit-details-marker {
  display: none;
}
