.btnPrimary {
  color: var(--on_primary);
  background: var(--primary);
   
  &:hover {
    background: var(--primary_hover);
  }

  &_active {
    background: var(--primary_active);
  }

  &:disabled {
    background: rgba(var(--primary_rgb), 0.4);
  }
}

.btnOutlinePrimary {
  color: var(--secondary-onSecondary);
  border: 1px solid var(--primary); 
  background: transparent;

  &:hover {
    color: var(--on_primary);
    border: 1px solid transparent;
    background: var(--primary_hover);
  }

  &_active {
    color: var(--on_primary);
    background: var(--primary_active);
  }

  &:disabled {
    background: rgba(var(--bg), 0.4);
  }
}

.btnSecondary {
  color: var(--secondary-onSecondary);
  background: var(--secondary-normal);

  &:hover {
    background: var(--secondary-hover);
  }

  &_active {
    background: var(--secondary-active);
  }

  &:disabled {
    background: rgba(var(--secondary-normal), 0.4);
  }
}

.btnOutlineSecondary {
  color: var(--secondary-onSecondary);
  border: 1px solid var(--secondary-normal);  
  background: transparent;

  &:hover {
    border: 1px solid transparent;
    background: var(--secondary-hover);
  }

  &_active {
    background: var(--secondary-active);
  }

  &:disabled {
    background: rgba(var(--bg), 0.4);
  }
}

.btnTertiary {
  color: var(--on_tertiary);
  background: var(--tertiary);

  &:hover {
    background: var(--tertiary_hover);
  }

  &_active {
    background: var(--secondary_active);
  }

  &:disabled {
    background: rgba(var(--tertiary_rgb), 0.4);
  }
}

.btnOutlineTertiary {
  color: var(--secondary-onSecondary);
  border: 1px solid var(--tertiary); 
  background: transparent; 

  &:hover {
    color: var(--on_tertiary);
    border: 1px solid transparent;
    background: var(--tertiary_hover);
  }

  &_active {
    color: var(--on_tertiary);
    background: var(--secondary_active);
  }

  &:disabled {
    background: rgba(var(--bg), 0.4);
  }
}

.btnSelectArm {
  color: var(--secondary-onSecondary);
  border: 1px solid var(--primary);

  &:hover {
    color: var(--on_primary);
    border: 1px solid transparent;
    background: var(--primary_hover);
  }
}
