$base0: #fff;
$base1: #bfc3d1;
$base2: #909090;
$base3: #6e7279;
$base4: #2a3243;
$base5: #1f2839;
$base6: #565e6c;
$base7: #c4c2c2;
$base8: #0d8eb8;
$base9: #333c51;
$base10: #5e6473;
$base11: #505869;
$base12: #646e83;
$base13: #8f8f8f;
$base14: #353c4d;
$palette6: #1d5975;
$grey: #5f6573;
$grey1: #3a414f;
$main: #00b5ea;
$blue-light: #4ad6ff;
$blue-light2: #12c6fb;
$active: #fa4b4b;
$active-second: #a33a4c;
$active-dark: #702d33;
$purple: #4e58ab;
$pink: #c644ea;
$yellow: #ffca08;
$blackout: #1f2839;
$transparent: transparent;
$box-shadow: 0 4px 20px rgba(0,0,0,0.2);
$transition: all 0.2s ease-in-out;
$info: $main;
$success: #04a881;
$success-dark: #174a4a;
$success-light: #24fe88;
$error: $active;
$warning: #ff8e25;
$easing-swing: cubic-bezier(0.25, 0.8, 0.5, 1);
$base-light: #f2f2f2;
$base-light-header: #416e97;
$base-light-border: #c6c6c6;
$bg-small-widget: #5a93c9;
$bc-small-widget: #202851;
$bg-light-body: #e0e0e0;
$bg-grid-line: #e3e3e3;
$bg-scroll: #333c52;

/* Новые */
$bgDefault: #1e2738;

:export {
  color_base0: $base0;
  color_base1: $base1;
  color_base2: $base2;
  color_base3: $base3;
  color_base4: $base4;
  color_base5: $base5;
  color_base6: $base6;
  color_base7: $base7;
  color_base8: $base8;
  color_base9: $base9;
  color_base10: $base10;
  color_base11: $base11;
  color_palette6: $palette6;
  color_grey: $grey;
  color_grey1: $grey1;
  color_blue: $main;
  color_blue-light2: $blue-light2;
  color_success: $success;
  color_success-dark: $success-dark;
  color_active: $active;
  color_active-second: $active-second;
  color_active-dark: $active-dark;
  color_purple: $purple;
  color_pink: $pink;
  color_warning: $warning;
  color_error: $error;
  color_yellow: $yellow;
  color_blackout: $blackout;
  easing_swing: $easing-swing;
  effect_transition: $transition;
  base-light: $base-light;
  base-light-header: $base-light-header;
  base-light-border: $base-light-border;
  bg-small-widget: $bg-small-widget;
  bg-light-body: $bg-light-body;
  bg-grid-line: $bg-grid-line;
}
