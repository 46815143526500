@import '~@/styles/variables.module';

.col {
  width: 33%;

  border-right: 1px solid $base2;
}

.col:last-child {
  border-right: none;
}

.title,
.value {
  margin: 20px 40px;
  padding-bottom: 15px;

  border-bottom: 1px solid $base2;
}

.title,
.total_title {
  font-size: 18px;
  line-height: 20px;
}

.value {
  color: $success;

  font-size: 24px;
  line-height: 28px;
}

.total_value {
  color: $yellow;

  font-size: 32px;
  line-height: 36px;
}


