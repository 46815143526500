.content_wrapper {
  display: flex;
  //margin-bottom: 20px;
  min-height: 62px;
  align-items: flex-end;
}

.content {
  display: flex;
  color: var(--txt_5);
  min-height: 53px;

  &__forecast {
    margin-left: auto;
    align-self: flex-start;
  }
}

.content_block {
  &__forecast {
    display: flex;
    flex-direction: column;
    top: -3px;
    position: relative;
  }
}

.value {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  text-align: center;

  &__forecast {
    order: 2;
    //color: var(--service-sussess);
  }

  &__measure {
    font-size: 18px;
    line-height: 140%;
  }

  &__green {
    color: var(--service-sussess)
  }
  &__red {
    color: var(--service-error);
  }
}

.name {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: var(--txt_3);
  text-align: center;

  &__forecast {
    text-align: end;
    line-height: 12px;
  }
}

.separator {
  width: 1px;
  height: 24px;
  background: var(--stroke-separator);
  margin-left: 16px;
  margin-right: 16px;
  align-self: center;
}
