.progressBar_wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  &__small {
    flex-direction: column;
    margin-top: 8px;
  }
}

.progressBar {
  overflow: hidden;
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background: var(--bg);
  box-shadow: inset 0 1px 2px var(--progress-bar-shadow);
}

.progressBar_inner {
  height: 100%;
  background: var(--surface_05);

  &__bad {
    //background: var(--service-error);
  }

  &__normal {
    //background: var(--service-warning);
  }

  &__good {
    //background: var(--service-sussess);
  }
}

.progressBar_text {
  flex-shrink: 0;
  margin-left: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 137%;
  color: var(--txt_2);

  &__small {
    line-height: 16px;
    margin-left: 0;
    margin-top: 3px;
  }
}
