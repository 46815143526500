@import '~@/styles/variables.module';
@import '~@/styles/link';

.user_content {
  margin-bottom: 30px;

  font-size: 16px;

  & h2 {
    margin: 0;
    padding-top: 25px;

    text-transform: uppercase;

    border-top: 1px solid rgba($base2, 0.5);

    font-size: 30px;
    font-weight: 300;
    line-height: 36px;
  }

  & p,
  & ol,
  & ul,
  & li {
    margin: 25px 0 0;
    padding: 0;
  }

  & ul,
  & ol {
    padding-left: 30px;
  }

  & ul {
    list-style-type: none;
  }

  & ul > li::before {
    content: '- ';
  }

  & a {
    @include link;
  }

  & b {
    font-weight: 500;
  }
}
