.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  min-height: 146px;
  padding: 16px 16px 6px 16px;

  transition: background 0.3s ease-in-out;

  border-radius: 8px;
  background: var(--bg-mini);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);

  justify-content: space-between;

  &__smallwidgetsimple {
    justify-content: flex-start;
  }
}

.header {
  display: flex;

  justify-content: space-between;
  align-items: flex-start;
}

.header_title {
  letter-spacing: 0.01em;

  color: var(--txt_1);

  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}

.info {
  position: relative;
  top: -6px;
  right: -6px;
}

.icon {
  position: absolute;
  right: 0;
  bottom: 0;

  box-sizing: border-box;

  color: var(--secondary-normal);
}
