@import '~@/styles/variables.module';

.table {
  width: 100%;
  height: min-content;
}

.tr {
  &:not(:last-child) {
    border-bottom: 1px solid rgba($base7, 0.3);
  }
}

.th {
  color: $yellow;

  font-size: 14px;
  line-height: 20px;
}

.td {
  padding-top: 13px;
  padding-bottom: 13px;

  font-size: 16px;
  line-height: 20px;

  &:not(:first-child) {
    text-align: center;
  }
}

.separate {
  width: 1px;
  height: 255px;

  background-color: rgba($base7, 0.3);
}
