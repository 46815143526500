.side-button {
  max-width: max-content;
  margin-right: 15px !important;
}
.side-button:last-child {
  margin-right: 0 !important;
}
.side-buttons__wrapper {
  width: 100%;
  margin-top: 30px;
}
.select-list-items__wrapper {
  display: flex;

  width: 100%;
}
.edit-users .buttons__wrapper {
  display: flex;

  width: 100%;
}
.bottom-side-buttons__wrapper {
  display: flex;

  margin-top: 40px;

  justify-content: flex-end;
}
