@import '~@/styles/variables.module';


.rating__container {
    cursor: pointer;
    margin-bottom: 6px;
    width: 80px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg);
    border-radius: 8px;

}

.rating__container_small {
    cursor: pointer;
    margin-bottom: 6px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg);
    border-radius: 4.5px;
}

.rating__place {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 31px;
    height: 21px;
    border-radius: 4.5px;
}

.rating__place_good{
    color: var(--add-txt_3);
    background: rgba(10, 209, 163, 0.1);
}

.rating__place_bad{
    color: var(--add-txt_1);
    background: rgba(250, 75, 75, 0.2);;
}


.rating__cup {
    max-width: 24px;
    max-height: 24px;
    color: #F3D55E;
}

.shake__effect {
    max-width: 24px;
    max-height: 24px;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both ;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }