@import '~@/styles/variables.module';

.container {
  border-radius: 4px;
  background: $success-dark;
}

.container_info {
  background: var(--alert_color-info);
}

.container_warning {
  background:  var(--alert_color-warning);
}

.container_error {
  background: var(--alert_color-error);
}

.container_success {
  background: var(--alert_color-success);
}
.container_purple {
  background: var(--alert_color-purple);
}

.container_grey {
  background: var(--alert_color-gray);
}
