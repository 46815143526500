
.container {
  padding: 10px;
}

.title {
  text-align: center;

  color: var(--txt_1);

  font-size: 20px;
  font-weight: 600;
  line-height: 136%;
}

.row {
  border-bottom: 1px solid var(--stroke-default);
}

.rating,
.pos,
.resRating {
  display: flex;
  flex: 0.5;

  text-align: center;

  color: var(--txt_1);

  align-items: center;
  justify-content: flex-start;
}

.iconArrowUp {
  transform: rotate(180deg);
}

.pos {
  flex: 0.8;
}

.position {
  box-sizing: border-box;
  min-width: 35px;
  margin-right: 3px;
  padding: 0 3px;

  color: var(--service-sussess);
  border-radius: 4px;
  background: rgba(var(--add-ui_3_rgb), 0.1);

  font-weight: 700;

  &_negative {
    color: var(--service-error);
    background: rgba(var(--add-ui_1_rgb), 0.2);
  }
}

.rating {
  font-weight: 500;

  justify-content: center;
}

.descRating {
  display: flex;
  flex: 4.5;

  color: var(--txt_1);

  align-items: center;
}

.nameRating {
  display: flex;
  flex: 2;

  text-align: right;

  color: var(--txt_2);

  align-items: center;
  justify-content: flex-end;
}

.resRating {
  display: flex;
  flex: 0.8;

  font-weight: 700;

  align-items: center;
  justify-content: center;
}
