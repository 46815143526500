@import '~@/styles/variables.module';

@keyframes slideLeft {
  from {
    transform: translateX(-100%);

    opacity: 0;
  }
  to {
    transform: translateX(0);

    opacity: 1;
  }
}

.wrapper_logo {
  height: 66px;
  margin-left: 6px;
}

.aside {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;

  overflow-x: hidden;

  box-sizing: border-box;
  width: 60px;
  height: 100vh;
  padding: 30px 0;

  transition: width 0.2s cubic-bezier(0, 0, 0, 1);
  animation: slideLeft 0.2s cubic-bezier(0, 0, 0, 1) forwards;
  animation-delay: 0.2s;

  @supports (backdrop-filter: blur(4px) saturate(100%)) {
    background: var(--bg-menu_01);

    backdrop-filter: blur(4px) saturate(100%);
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 30px;

    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: transparent;
  }

  &:hover::-webkit-scrollbar {
    background: $base4;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: $main;
  }

  &_active {
    width: 300px;

    background: var(--bg-menu_01_open);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  }
}

.link {
  display: block;
}

.logo {
  display: block; 
}

.logo_dit {
  margin-left: 15px;
}

.btn {
  border: 0;
  background: transparent;

  font-family: 'roboto';
  font-size: 14px;
}


.asideLink {
  position: relative;

  display: flex;

  box-sizing: border-box;
  width: 300px;
  padding: 10px 20px 10px 17px;

  cursor: pointer;
  text-decoration: none;

  opacity: 0.6;
  color: #fff;

  align-items: center;

  height: 48px;
  margin: 0 auto;

  &:hover {
    transition: color 0.3s ease-in-out;
    color: var(--menu_01_active);
    opacity: 1;
  }

  &:hover::after {
    position: absolute;
    top: 50%;
    left: 5px;

    height: 48px;

    content: '';
    transform: translateY(-50%);

    background: rgba(255, 255, 255, 0.1);
  }

  &:global(.active) {
    opacity: 1;
    color: var(--menu_01_active);
  }
}

.asideMin:hover::after {
  width: 48px;

  border-radius: 50%;
}



.asideMax {
  &:hover::after {
    width: calc(100% - 10px);

    border-radius: 5px;
  }

  &:global(.active::after) {
    position: absolute;
    top: 50%;
    left: 5px;
    width: calc(100% - 10px);
    border-radius: 5px;
    height: 48px;

    content: '';
    transform: translateY(-50%);

    background: rgba(255, 255, 255, 0.1) !important;
  }

  &:global(.active):hover::after {
    background: none;
  }
}

.textMenu {
  color: $base0;
}

.iconShrink {
  flex-shrink: 0;
}