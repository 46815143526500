@import '~@/styles/variables.module';

@keyframes slideUp {
  from {
    transform: scale(0.95);

    opacity: 0;
  }
  to {
    transform: scale(1);

    opacity: 1;
  }
}

.container {
  max-width: 651px;
  margin: 0 auto;

  background: var(--bg);
}

.logo {
  animation: slideUp 0.2s ease-in-out forwards;
  animation-delay: 0.2s;

  opacity: 0;
}

.logo_dit {
  width: 400px;
  height: 102px;
}

.title {
  transform: translateY(0);
  animation: slideUp 0.2s ease-in-out forwards;
  animation-delay: 0.3s;
  text-align: center;

  opacity: 0;

  font-size: 48px;
  font-weight: 500;
}

.buttonsContainer {
  animation: slideUp 0.2s ease-in-out forwards;
  animation-delay: 0.4s;

  opacity: 0;
}

.text {
  transform: translateY(0);
  animation: slideUp 0.2s ease-in-out forwards;
  animation-delay: 0.4s;
  text-align: center;

  opacity: 0;

  font-size: 30px;
}

.info {
  transition: opacity 0.2s ease-in-out, top 0.2s ease-in-out;
  transition-delay: 1s;
  animation-delay: 0.9s;

  opacity: 0;
  color: $base3;

  font-size: 18px;
}

.changeLang {
  display:flex;
  justify-content: end;
}
