@import '~@/styles/variables.module';

.header {
  color: rgba($base0, 0.5);

  font-size: 11px;
}

.wrapper {
  position: relative;

  padding-right: 25px;

  cursor: pointer;

  color: inherit;
  border: none;
  outline: none;
  background: transparent;

  font-size: inherit;

  &:hover .icon {
    opacity: 1;
  }
}

.icon {
  position: absolute;
  top: 0;
  right: 0;

  transition: $transition;

  color: $base0;
}
