.app {
  position: relative;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.bg {
  background: var(--bg);
}
