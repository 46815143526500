@import '~@/styles/variables.module';

.description {
  color: $base1;

  font-size: 13px;
  line-height: 20px;
}

.label {
  text-align: right;

  opacity: 0.8;
  color: var(--txt_2);

  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;

  align-self: flex-end;
}

.content {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.container {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px 12px;

  pointer-events: auto;

  flex-shrink: 1;

  svg .main_g {
    width: 100%;
    height: 100%;

    transform: translate(50%, 50%);
  }
}

.iconColor {
  color: var(--icon_on_widget_header_normal);

  &:hover {
    //color: var(--icon_on_widget_header_hover);
    opacity: .6;
  }
}
