@import '~@/styles/variables.module';

.label {
  opacity: 0.8;

  font-size: 13px;
}

.table {
  line-height: calc(20 / 14);
}
