@import '@/styles/variables.module.scss';

.table {
  overflow: hidden;

  max-width: 330px;

  border: 1px solid rgba($base1, 0.4);
  border-radius: 5px;
}

.row {
  display: flex;

  &:nth-child(2n - 1) {
    background-color: rgba($grey, 0.3);
  }
}

.cell {
  position: relative;

  display: flex;

  padding: 10px;

  align-items: center;

  &:first-child {
    width: 30%;

    color: $yellow;
    background-color: rgba($grey, 0.3);
  }

  &:nth-child(2) {
    box-sizing: border-box;
    width: 58%;
    padding-left: 40px;

    justify-self: center;
  }
}

.wrap_icon {
  position: absolute;
  top: 50%;
  right: 0;

  transform: translateY(-50%);
}
