@import '~@/styles/variables.module';

.prev {
  min-width: 31px;
  height: 31px;
  padding: 0;
}

.line {
  border-bottom: 1px rgba(255,255,255,0.2) solid;
}

.title,
.itemTitle,
.value {
  vertical-align: middle;

  font-size: 16px;
  line-height: 20px;
}

.table {
  width: 100%;
  height: 100%;
}

.value,
.indicator {
  text-align: center;
}

.indicator {
  vertical-align: middle;
}

.good {
  color: $yellow;
}

.best {
  color: $success;
}

.worst {
  color: $error;
}

