@import '~@/styles/variables.module';

.container {
  position: relative;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  min-height: 118px;
  padding: 10px;
  padding-bottom: 15px;

  line-height: 1;

  align-items: flex-start;
  justify-content: space-between;
}

.theme {
  color: $base0;
  border: 2px solid var(--miniwidget-stroke);
  border-radius: 5px;
  background: var(--bg-miniwidget);
}

.full_width {
  width: 100%;
}

.header {
  display: flex;

  width: 100%;
  margin-bottom: 5px;

  justify-content: space-between;
}

.title {
  font-size: 12px;
  font-weight: 500;
}

.title_text {
  display: block;

  font-size: 16px;
  line-height: 19px;
}

.title_description {
  display: block;

  color: $base0;

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.info {
  display: flex;

  align-content: center;
}

.text {
  text-align: center;

  font-size: 24px;
  font-weight: 500;
}

.procentage {
  position: absolute;
  right: -8px;
  bottom: -16px;

  padding: 5px;

  border-radius: 5px;

  font-size: 12px;
  line-height: 20px;

  &_green {
    background-color: $success;
  }

  &_red {
    background-color: $error;
  }
}

.spinner_container {
  box-sizing: border-box;
  min-height: 100px;

  border-radius: 5px;
  background: var(--bg);
}
