.select {
  position: relative;
  display : flex;
  width   : 100%;

  align-items: center;
}

.select>span {
  margin-right: auto;
  font-size   : 15px;
}

.select__box {
  position: relative;
  overflow: hidden;
  width   : 100%;
  padding : 20px 0 10px;
}

.select__label {
  position   : absolute;
  top        : 6px;
  left       : 0;
  white-space: nowrap;
  color      : var(--txt_3);
  font-size  : 13px;
  line-height: 14px;
}

.select__input {
  position: relative;
  width   : 100%;
}

.select__search {
  box-sizing      : border-box;
  width           : 100%;
  padding         : 0;
  padding-left    : 25px;
  cursor          : pointer;
  text-overflow   : ellipsis;
  color           : var(--txt_1);
  border          : none;
  border-bottom   : 1px solid var(--stroke-default);
  outline         : none;
  background-color: transparent;
  line-height     : 30px;
}

.select__input.is-only-read .select__search {
  padding-left: 0;
  cursor      : inherit;
}

.select__search::placeholder {
  color: var(--txt_1);
}

.select__dropdown {
  position        : absolute;
  top             : 100%;
  right            : 0;
  z-index         : 1;
  overflow        : hidden;
  box-sizing      : border-box;
  max-height      : 0;
  width: 100%;
  transition      : all 0.3s ease-in-out;
  border-radius   : 5px;
  background-color: var(--grey);
  box-shadow      : 0 4px 20px rgba(0, 0, 0, 0.25);
}

.select__dropdown::-webkit-scrollbar {
  width: 5px;
  height: 30px;
  background: var(--base4);
}

.select__dropdown::-webkit-scrollbar-thumb {
  background: var(--blue);
  border-radius: 5px;
}

.select__dropdown.is-opened {
  max-height: 200px;
}

.select__list {
  margin         : 0;
  padding        : 15px;
  list-style-type: none;
}

.select__option {
  cursor     : pointer;
  transition : all 0.3s ease-in-out;
  color      : var(--base0-medium);
  font-size  : 14px;
  line-height: 14px;
}

.select__option:not(:last-child) {
  margin-bottom: 10px;
}

.select__option:hover {
  color: var(--base0);
}
