@import '~@/styles/variables.module';

.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  align-items: center;
}

.title {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;

  text-align: center;
}

.subtitle {
  width: 100%;

  text-align: center;
}

.text {
  text-align: center;

  font-size: 16px;
  line-height: 20px;
}

.number {
  color: $yellow;

  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
}

.chart {
  width: 100%;
  height: 100%;
  max-height: 165px;
  margin-top: auto;
}

@media (max-width: 1550px) {
  .text {
    font-size: 14px;
    line-height: 18px;
  }
}
