@import '~@/styles/variables.module';

.card {
  position: relative;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding: 40px;

  color: var(--txt_1);
  border-radius: 10px;
  background: var(--bg-widget);
}

.full_height {
  height: 100%;
}

.header {
  font-size: 35px;

  flex: 0 0 auto;

  //color: var(--txt_1);

  text-align: center;
}

.body {
  flex: 1 1 auto;
}

.overflow_hidden {
  overflow: hidden;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;

  display: flex;

  align-items: center;
  justify-content: center;
  width: 30px;

  height: 30px;
  padding: 0;

  cursor: pointer;
  transition: color 0.3s ease-in-out;
  color: var(--primary);
  border: none;

  outline: none;
  background: transparent;

  &:hover {   
    opacity: .8;
  }
}

.footer {
  flex: 0 0 auto;
}
