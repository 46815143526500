@import '~@/styles/variables.module';

.container {
  height: 40px;

  border-bottom: 1px solid $base2;
}

.content {
  display: flex;

  height: 100%;

  align-items: center;
}

.wrapper {
  display: flex;
  flex: 1 1 auto;

  align-items: center;
}

.input {
  width: 38px;

  color: $base0;
  border: none;
  outline: none;
  background: transparent;

  font-size: 15px;
}

.divider {
  margin-right: 4px;
  margin-left: 4px;
}
