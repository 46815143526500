@import '~@/styles/variables.module';

.container {
  border-bottom: 2px solid rgba($base0, 0.3);
}

.name {
  font-size: 20px;
}

.value {
  font-size: 16px;
}