@import '~@/styles/variables.module';
$duration: 300ms;

:export {
  duration: $duration;
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity $duration $easing-swing;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity $duration $easing-swing;
}
