@import '~@/styles/variables.module';

.wrapper {
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;

  box-sizing: border-box;
  height: 100%;  
}

.close {
  position: absolute;
  top: 0;
  right: 100%;

  width: 46px;
  height: 46px;

  cursor: pointer;

  color: $base0;
  border: none;
  border-radius: 5px 0 0 5px;
  outline: none;  
}
