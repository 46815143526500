@import '~@/styles/variables.module';

@keyframes showBlock {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container,
.wrapper {
  width: 100%;
  height: 100%;

  display: flex;

  justify-content: center;
  align-items: center;
}

.spiner {
  position: relative;

  box-sizing: border-box;

  transform: rotate(45deg);
  transform-origin: 50% 50%;
}

.spin {
  position: absolute;

  box-sizing: border-box;
  width: 47%;
  height: 47%;

  animation: showBlock 0.5s linear infinite;

  border: 10px solid;
}

.spin_1 {
  top: 0;
  left: 0;

  animation-delay: 0.1s;

  //border-color: $main;
  border-color: var(--primary);
}

.spin_2 {
  top: 0;
  right: 0;

  animation-delay: 0.2s;

  //border-color: #acabab;
  border-color: var(--secondary-normal);
}

.spin_3 {
  right: 0;
  bottom: 0;

  animation-delay: 0.3s;

  //border-color: #fff;
  border-color: var(--secondary-active);
}

.spin_4 {
  bottom: 0;
  left: 0;

  animation-delay: 0.4s;

  //border-color: #acabab;
  border-color: var(--secondary-normal);
}
