@import '~@/styles/variables.module';

.title {
  text-align: left;

  color: var(--txt_1);

  font-size: 16px;
  line-height: 24px;
}

.panel {
  position: relative;
}

.inner {
  display: flex;

  list-style-type: none;

  border-radius: 5px;
  background: var(--bg);

  justify-content: space-between;
}

.item {
  padding-right: 15px;
  padding-left: 0;
}

.item:first-child {
  padding-left: 0;
}

.item:last-child {
  padding-right: 0;
}

.item:not(:last-child) {
  border-right: 1px solid var(--stroke-separator);
}

.itemTitle {
  text-align: center;

  color: var(--txt_1);

  font-size: 12px;
  line-height: 20px;
}

.itemInfo,
.itemTotal {
  color: var(--txt_1);

  font-size: 16px;
  line-height: 19px;
}

.itemTotal {
  color: var(--txt_3);
}

.itemTotal {
  opacity: 0.5;
}
