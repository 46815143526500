@import '~@/styles/variables.module';

.table {
  width: 100%;

  border-radius: 5px;
  background: $base9;
}

.table tr:nth-child(2) {
  border-bottom: 1px solid rgba($base0, 0.5);
}

.table tr th {
  padding-bottom: 5px;

  text-align: left;

  background: $base4;
}

.table tr th:nth-child(2) {
  text-align: center;
}

.table tr td {
  padding: 10px 20px;
}

.type {
  margin: 10px 0;

  font-size: 14px;
  line-height: 14px;
}

.period {
  margin: 10px 20px 10px 0;

  text-align: end;

  color: $yellow;

  font-size: 20px;
  line-height: 20px;
}







