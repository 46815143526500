@import '~@/styles/variables.module';
$border-color: #6d727a;

.wrapper {
  box-sizing: border-box;
  width: 100%;

  color: var(--txt_1);
  border-radius: 5px;
}

.wrapper_border {
  border: 1px solid var(--stroke-default);
}

.table {
  width: 100%;

  border-collapse: collapse;
}

.tr {
  border-top: 1px solid var(--stroke-default);
}

.tr_position {
  position: relative;
}

.tfoot_tr {
  border-top: none;
}

.th,
.td {
  padding: 5px 10px;

  vertical-align: middle;

  &:not(:last-child) {
    border-right: 1px solid var(--stroke-default);
  }
}

.td {
  background: var(--table_default);
}

.td_decorated {
  background: var(--table_select);
}

.tfoot_td {
  position: relative;

  background: var(--table_total_default);

  &::before {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 1px;

    content: '';

    background: var(--stroke-default);
  }
}

.th {
  position: relative;

  color: var(--txt_widget);
  background: var(--table_header);

  &:hover .sort {
    opacity: 1;
  }
}

.th_sticky {
  position: sticky;
  top: 0;
}

.tfoot_sticky {
  position: sticky;
  bottom: 0;
}

.th_sticky_column {
  position: sticky;
  z-index: 2;
  left: 0;
}

.td_sticky_column {
  z-index: 1;

  background: var(--table_default);
}

.td_color {
  color: var(--on_tertiary);
}

.th_dense,
.td_dense {
  padding: 0 10px;

  font-size: 13px;
}

.bigPappingForCell {
  padding: 15px 20px;
}

.sort {
  padding: 0;

  cursor: pointer;
  transition: $transition;

  color: var(--icon_on_primary);
  border: none;
  background-color: transparent;

  &:hover {
    color: var(--primary);
  }
}

.sort_container {
  display: inline-flex;

  align-items: center;
}

.error {
  background-color: var(--table_error_default);

  & td {
    background: var(--table_error_default);
  }
}

.success {
  background-color: var(--table_success_default);

  & td {
    background: var(--table_success_default);
  }
}
