.no-drag:hover {
  cursor: default;
}
.close-edit-users {
  display: flex;

  border: none;
  background: transparent;

  align-items: center;
  justify-content: center;
}
.close-edit-users:hover {
  cursor: pointer;
}
.edit-users {
  margin-bottom: 20px;
}
.selectList__item {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 45%;

  justify-content: flex-start;
}
.left-side {
  position: relative;

  display: flex;

  width: 100%;
  max-width: 48%;
  padding-right: 2%;

  justify-content: space-between;
  flex-wrap: wrap;
}
.left-side:after {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;

  width: 1px;
  height: 90%;

  content: '';

  background: var(--base2);

  align-items: center;
}
.buttons__wrapper .left-side:after {
  display: none;
}
.UsersManagement .filter-second__dropdown {
  width: 100%;
  max-width: 100%;
}
.right-side {
  width: 100%;
  max-width: 48%;
  padding-left: 2%;

  justify-content: space-between;
}
.UsersManagement .arms-list {
  display: flex;

  flex-wrap: wrap;
}
.UsersManagement .arm {
  margin: 0 20px 20px 0;
}
.UsersManagement .arms-list__header {
  margin-bottom: 15px;

  font-size: 18px;
}
.UsersManagement .selected-filter__dropdown.is-opened {
  max-height: 300px;
}
