@import '~@/styles/variables.module';

.wrapper {
  width: 100vw;
  height: 100vh;
}

.window {
  position: relative;

  width: 100%;
  max-width: 600px;
  padding: 25px 0 70px 0;

  border-radius: 20px;
  background: $base4;

  &::after {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';
    animation: mask 0.4s ease-in-out forwards;
    animation-delay: 0.4s;

    border-radius: 20px;
    background: var(--base5);
  }
}

.form {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  padding: 0 50px;

  color: $base0;
}

.btnForm {
  color: #fff;
  border: 2px solid $main;
  background: $main;

  &:hover {
    border: 2px solid $main;
    background: transparent;
  }
}

.logotype {
  margin-bottom: 30px;

  animation: slideUp 0.2s ease-in-out forwards;
  animation-delay: 0.8s;

  opacity: 0;
}

.pass_link {
  width: max-content;
  margin: 0 auto;

  animation: slideUp 0.2s ease-in-out forwards;
  animation-delay: 1s;

  opacity: 0;
  border: none;
  background: transparent;
}

.animation_btn {
  transition: opacity 0.2s ease-in-out;
  animation: slideUp 0.2s ease-in-out forwards;
  animation-delay: 1.1s;

  opacity: 0;
}

@keyframes slideUp {
  from {
    transform: scale(0.95);

    opacity: 0;
  }
  to {
    transform: scale(1);

    opacity: 1;
  }
}

@keyframes mask {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);

    border-radius: 100%;
  }
}

.changeLang {
  display:flex;
  justify-content: end;
}