@import '~@/styles/variables.module';

@mixin link {
  cursor: pointer;
  text-decoration: underline;

  color: var(--txt_link);

  &:hover {
    text-decoration: none;
  }
}

.link {
  @include link;
}
