@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;

  src: url('@/assets/fonts/Roboto-Thin.woff2') format('woff2'),
  url('@/assets/fonts/Roboto-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;

  src: url('@/assets/fonts/Roboto-Light.woff2') format('woff2'),
  url('@/assets/fonts/Roboto-Light.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;

  src: url('@/assets/fonts/Roboto-Medium.woff2') format('woff2'),
  url('@/assets/fonts/Roboto-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;

  src: url('@/assets/fonts/Roboto-Black.woff2') format('woff2'),
  url('@/assets/fonts/Roboto-Black.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;

  src: url('@/assets/fonts/Roboto-Bold.woff2') format('woff2'),
  url('@/assets/fonts/Roboto-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;

  src: url('@/assets/fonts/Roboto-Regular.woff2') format('woff2'),
  url('@/assets/fonts/Roboto-Regular.woff') format('woff');
}

body::-webkit-scrollbar {
  width: 5px;
  height: 30px;

  background: var(--bg);
}

body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--surface_06);

}

body::-webkit-scrollbar-thumb:hover {
  background: var(--primary_hover);
}

.app{
  color: var(--app-color);
}

.app__content {
  width: 100%;
  height: 100%;

  flex-shrink: 1;
}

.app__main {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  height: 100%;

  transition: translateY(5px);
  animation: slideUp 0.2s cubic-bezier(0, 0, 0, 1) forwards;
  animation-delay: 0.6s;

  opacity: 0;

  flex-shrink: 1;
}

.container {
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
}

.react-grid-item.is-activeCalendar {
  /* position: relative; */
  z-index: 1;
}

.react-grid-layout {
  position: relative;
}

.react-grid-placeholder {
  transition: transform 0.1s ease-in-out, background 0.2s ease-in-out;

  border: 1px solid var(--blue);
  border-radius: 5px;
  background: var(--blue-transparent);
}

.react-draggable {
  transition: box-shadow 0.2s ease, scale 0.2s ease;
  transform: scale(1);
  pointer-events: none;

  border-radius: 5px;
  box-shadow: 0 0 0 1px transparent, 0 0 20px 0 transparent, 0 10px 20px transparent;
}

.react-draggable-dragging {
  z-index: 2;

  transform: scale(0.5);

  border-radius: 5px;
  box-shadow: 0 0 0 0 var(--blue), 0 0 20px -10px rgba(0, 181, 234, 1);
}

.react-grid-item:focus-within {
  position: relative;
  z-index: 2;
}

.react-grid-item .spinner_wrap {
  width: 100%;
  height: 100%;

  border-radius: 5px;
  background: var(--base4);
}

/*inputs*/
.input_default {
  position: relative;
}

.input_default .input__src {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 0 10px 25px;

  color: var(--base0);
  border: none;
  border-bottom: 1px solid var(--base3);
  outline: none;
  background: transparent;

  font-family: 'Roboto';
  font-size: 14px;

  appearance: none;
}

.input_default .input__src:focus {
  border-bottom: 1px solid var(--blue);
}

.input_default .input__src:-webkit-autofill {
  border: 0;
  border-bottom: 1px solid var(--green);
  background: transparent;
  box-shadow: inset 0 0 0 50px var(--base4);

  font-size: 14px;

  -webkit-text-fill-color: var(--base0);
}

.input_default .input__src:-webkit-autofill:hover {
  font-size: 14px;
}

.input_default .icon {
  position: absolute;
  top: calc(50% + 5px);
  left: 0;

  transform: translateY(-50%);
}

.input_default .input__placeholder {
  position: absolute;
  top: 0;
  left: 25px;

  color: var(--base3);

  font-size: 10px;
}

.input_checkbox .input__text {
  color: var(--base0);
}

.button_logout {
  display: flex;

  width: 30px;
  height: 30px;
  margin-right: 0;
  margin-left: auto;
  padding: 0;

  cursor: pointer;

  color: #6e717b;
  border: none;
  outline: none;
  background: transparent;

  align-items: center;
  justify-content: center;
}

.button_logout:hover .icon {
  fill: var(--base0);
}

main {
  height: 100%;
}

.hovered-react-grid-item {
  z-index: 1;
}@keyframes slideUp {
  from {
    transform: translateY(10px);

    opacity: 0;
  }
  to {
    transform: translateY(0);

    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(100%);

    opacity: 0;
  }
  to {
    transform: translateX(0);

    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(-100%);

    opacity: 0;
  }
  to {
    transform: translateX(0);

    opacity: 1;
  }
}
