@import '~@/styles/variables.module';

.wrapper {
  border-radius: 0 5px 5px 5px;
  background: var(--bg-widget);
  color: var(--txt_1);
}

.label {
  text-align: center;

  font-size: 26px;
  line-height: 32px;
}
