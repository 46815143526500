@import '~@/styles/variables.module';

.wrapper {
  display: flex;
  flex-direction: row;

  box-sizing: border-box;
  width: 100%;
  min-width: 550px;
  max-width: 600px;
  height: auto;
  max-height: 300px;
  padding: 15px;

  border-radius: 5px;
  background-color: $base5;
}

.blockInfoWrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-width: max-content;
  max-width: 32.5%;

  flex-wrap: wrap;
  justify-content: space-between;
  &:nth-of-type(2) {
    margin-right: 10px;

    align-content: flex-end;
  }
}

.blockInfo {
  display: flex;
  flex-direction: column;
}

.infoItem {
  display: inline-block;
  overflow: hidden;

  width: max-content;
  max-width: 200px;
  padding: 0;

  text-align: left;
  white-space: nowrap;
  white-space: pre-wrap;
  text-decoration: none;
  text-overflow: none;

  line-height: 14px;
  &_active {
    width: 200px;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.infoMore {
  cursor: pointer;

  color: $main;

  font-size: 12px;
  line-height: 20px;
}

.blockOperator {
  flex-direction: column;

  box-sizing: border-box;
  width: 35%;
  margin-left: 15px;
  padding-left: 15px;

  border-left: 1px solid rgba($grey, 0.8);
}

.operatorName {
  text-align: center;

  color: $base0;

  font-size: 14px;
  line-height: 14px;
}

.operatorPosition {
  text-align: center;

  color: $main;

  font-size: 14px;
  line-height: 20px;
}

.operatorAvatar {
  text-align: center;
}

.operatorAvatarSrc {
  position: relative;
  z-index: 2;

  overflow: hidden;

  box-sizing: border-box;
  width: 60px;
  height: 60px;
  padding: 5px;

  border-radius: 100px;
  box-shadow: 0 0 0 2px $success;

  flex-shrink: 0;
  object-fit: cover;
}

.spinner {
  height: 144px;  
}

