.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 377px;

  width: 100%;
  min-height: 377px;
}

.chart {
  position: relative;

  width: 100%;
  height: 400px;
  margin-bottom: 20px;
}