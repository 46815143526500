.container {
  width: 100%;
  height: 75%;
}

.wrapper {
  flex-direction: column;
  width: 100%;
  // height: 100%;
  //justify-content: center;
}

.value {
  //margin: 1em 0 0 0;
  font-size: 24px;
  font-weight: 700;
}

.name {
  /*transform: translateY(10px);*/
  text-align: center;
  font-size: 16px;
}

.legendary {
  display: flex;
  flex-direction: column;
  transform: translate(0, -20px);
  line-height: 1.25;
  align-items: center;
  justify-content: center;
}

.arrow {
  // уменьшаем размер стрелки
  transform: scale(0.85);
  transform-box: fill-box;
  transform-origin: center center;
}
