.content_wrapper {
  display: flex;
  //margin-bottom: 20px;
  min-height: 53px;
  align-items: center;
  justify-content: center;
  color: var(--txt_5);
}

.content_block {
  width: 100%;
}

.value {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  &__green {
    color: var(--service-sussess)
  }
  &__red {
    color: var(--service-error);
  }
}

.name {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--txt_3);
  text-align: center;
}

.separator {
  width: 1px;
  height: 24px;
  background: var(--stroke-separator);
  margin-left: 16px;
  margin-right: 16px;
  align-self: center;
}
