@import '~@/styles/variables.module';

.card {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  height: 100%;
}

.title {
  flex: 0 0 auto;

  font-size: 24px;
}

.content {
  overflow-y: auto;
  flex: 1 1 auto;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: var(--bg-widget);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--surface_06);

    &:hover {
      background: var(--primary_hover);
    }
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
  }
}

.shrink {
  flex: 0 0 auto;
}
