@import '~@/styles/variables.module';

.list {
  display: flex;

  color: $base2;

  align-items: center;
}

.list_light {
  color: $base5;
}
