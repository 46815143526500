@import '~@/styles/variables.module';

.figure {
  display: flex;
  flex: 0 0 auto;

  width: 15px;
  height: 15px;
  margin-right: 5px;

  cursor: pointer;
  transition: $transition;
  text-align: center;
  text-align: center;

  color: $main;
  border: 1px solid currentColor;
  border-radius: 9999px;
  outline: none;

  justify-content: center;
  align-items: center;

  &::before {
    display: inline-block;

    width: 11px;
    height: 11px;

    content: '';
    transition: $transition;
    vertical-align: middle;

    opacity: 0;
    border-radius: 9999px;
    background: currentColor;
  }

  &.is_checked::before {
    opacity: 1;
  }
}

.input {
  display: none;
}

.label {
  flex: 0 1 auto;

  cursor: pointer;
  user-select: none;

  font-size: 12px;
}
