@import '~@/styles/variables.module';

:global(.react-calendar) {
  width: 340px;
}

:global(.react-calendar__navigation) {
  height: 60px;
  padding: 0 10px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: var(--bg-w-head);
}

:global(.react-calendar__month-view) {
  //padding: 17px 28px 12px;
  padding: 10px 30px 10px;
  background: var(--bg-widget);
}

:global(.react-calendar__tile) {
  line-height: 136%;
  padding: 10px 0 8px 0;
}

:global(.react-calendar__month-view__weekdays__weekday) {
  font-size: 16px;
  line-height: 136%;
  padding: 10px 0 8px 0;
}

:global(.react-calendar__year-view__months),
:global(.react-calendar__decade-view__years),
:global(.react-calendar__century-view) {
  padding: 32px 14px 33px;
}

:global(.react-calendar__year-view__months__month),
:global(.react-calendar__decade-view__years__year),
:global(.react-calendar__century-view__decades__decade) {
  margin-bottom: 5px !important;
  padding: 11px 5px !important;
  border-radius: 5px;
}


.calendar {
  box-sizing: border-box;
  width: 340px;

  border-radius: 5px 5px 0 0;
}

.bottom {
  height: 70px;
  color: var(--txt_widget);
  border-radius: 0 0 5px 5px;
  background: var(--bg-w-head);
}

.date_labels {
  font-size: 14px;
  line-height: 136%;
}

.shadow {
  border-radius: 5px;
  filter: var(--shadow_2);
}

