@import '~@/styles/variables.module';

.wrapper {
  cursor: pointer;
}

.container {
  padding: 2px;
}

.active {
  border-radius: 10px;
  outline: 2px solid var(--stroke-active);
}

.inside {
  border: 2px solid var(--surface_04);  
  border-radius: 8px; 
}

.text {
  font-size: 14px;
  line-height: 16px;
  color: var(--txt_2);

  &_active{
    color: var(--txt_1);
  }
}
