.themesIcon {
  max-height: 64px;

  border: 2px solid var(--secondary-normal);
  border-radius: 50%;

  svg {
    border-radius: 50%;
  }
}
