@import '~@/styles/variables.module';

.container {
  width: 100%;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.hr {
  //font-size: 20px;
  font-weight: 500;
  //padding: 16px;
  line-height: 136%;

  &__6 {
    font-size: 36px;
    padding: 10px 20px;
  }

  &__13 {
    font-size: 26px;
    padding: 17px 20px;
  }

  &__17 {
    font-size: 30px;
    padding: 14px 20px;
  }

  &__23 {
    font-size: 20px;
    padding: 16px 20px;
  }
}

.hr_full_width {
  //width: 100%;
  width: 60%;
}

.cell {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.best {
  border-right: 2px solid var(--service-sussess);
  border-left: 2px solid var(--service-sussess);
position: relative;
  //&:after {
  //  display: block;
  //  position: absolute;
  //  box-sizing: border-box;
  //  top: -1px;
  //  left: 0;
  //  content: '';
  //  width: 100%;
  //  height: 100%;
  //  box-shadow: -2px 0px 0px 0px var(--service-sussess) inset, 2px 0px 0px 0px var(--service-sussess) inset;
  //  //box-shadow: -2px -2px 0px 0px var(--service-sussess) inset, 2px 2px 0px 0px var(--service-sussess) inset;
  //}

  & td {
    z-index: -1;
    background: var(--table_success_default);
  }

  &:nth-child(even) td {
    background: var(--table_success_select);
  }
}

.best_first {
  border-top: 2px solid var(--service-sussess);
  //&:after {
  //  box-shadow: -2px 2px 0px 0px var(--service-sussess) inset, 2px 0px 0px 0px var(--service-sussess) inset;
  //}
}

.best_last {
  border-bottom: 2px solid var(--service-sussess);
  //&:after {
  //  box-shadow: -2px 0px 0px 0px var(--service-sussess) inset, 2px -2px 0px 0px var(--service-sussess) inset;
  //}
}

.worst {
  border-right: 2px solid var(--service-error);
  border-left: 2px solid var(--service-error);

  & td {
    background: var(--table_error_default);
  }

  &:nth-child(even) td {
    background: var(--table_error_select);
  }
}

.worst_first {
  border-top: 2px solid var(--service-error);
}

.worst_last {
  border-bottom: 2px solid var(--service-error);
}

.text_success {
  color: var(--service-sussess);
}

.text_error {
  color: var(--service-error);
}

.position_wrapper {
  min-width: 55px;
}

.position {
  font-weight: 700;
  line-height: 80%;
  box-sizing: border-box;
  min-width: 42px;
  margin-right: 3px;

  padding: 8px 4px;
  color: var(--service-sussess);
  border-radius: 6px;
  background: rgba(var(--add-ui_3_rgb), .1);

  &__6 {
    font-size: 36px;
    min-width: 70px;
  }

  &__17,
  &__13 {
    min-width: 60px;
    font-size: 30px;
  }

  &__23 {
    min-width: 42px;
    font-size: 20px;
  }
}

.position_negative {
  background: rgba(var(--add-ui_1_rgb), .2);
  color: var(--service-error);
}

.iconArrowUp {
  transform: rotate(180deg);
}

.name {
  min-height: 30px;
  text-align: left;
}

.name_td {
  padding: 6px 20px;
  //padding: 18px 20px;
  &__6,
  &__13 {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  &__17 {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

.rating_td {
  &__6 {
    padding: 14px 10px;
  }

  &__13 {
    padding: 26px 10px;
  }

  &__17 {
    padding: 16px 10px;

  }

  &__23 {
    padding: 16px 10px;
  }
}

.td_size {
  &__6 {
    font-size: 48px;
    line-height: 100%;
  }

  &__13 {
    font-size: 40px;
    line-height: 100%;
  }

  &__17 {
    font-size: 36px;

    line-height: 136%;
  }

  &__23 {
    font-size: 20px;
    line-height: 136%;
  }
}

.rating_wrapper {
  font-weight: 500;
  margin-left: auto;
  text-align: right;
  color: var(--txt_1);
  line-height: 136%;
  min-height: 32px;
}

.avatar {
  position: relative;
  z-index: 2;

  overflow: hidden;

  flex-shrink: 0;
  box-sizing: border-box;
  margin-right: 20px;

  padding: 5px;

  border-radius: 50%;
  border: 2px solid var(--primary);


  &__6,
  &__13 {
    width: 80px;
    height: 80px;
  }

  &__17 {
    width: 60px;
    height: 60px;
  }

  &__23 {
    width: 50px;
    height: 50px;
  }
}

.placeholderGap {
  font-size: 24px;
  line-height: 136%;
  color: var(--txt_2);

  &__23 {
    font-size: 17px;
    padding: 20px 20px;
  }

  &__6,
  &__13 {
    padding: 37px 20px;
  }

  &__17 {
    padding: 24px 20px;
  }

  &__hidden {
    display: none;
  }
}
