@import '~@/styles/variables.module';
$greyLocal: #c4c4c4;

.container {
  box-sizing: border-box;

  border-radius: 5px;
}

.green {
  border: 1px solid  var(--service-sussess);
  background: var(--alert_color-success);
}

.red {
  border: 1px solid  var(--service-error);
  background: var(--alert_color-error);
}

.blue {
  border: 1px solid  var(--service-info);
  background: var(--alert_color-info);
}

.yellow {
  border: 1px solid var(--service-warning);
  background: var(--alert_color-warning);
}
.purple {
  border: 1px solid var(--service-purple);
  background: var(--alert_color-purple);
}

.grey {
  border: 1px solid var(--alert_color-gray_border);
  background: var(--alert_color-gray);
}

.title {
  color: $base0;

  font-size: 16px;
  line-height: 19px;
}

.link {
  padding: 0;

  cursor: pointer;

  color: inherit;
  border: 0;
  background: transparent;
}

.message {
  padding-left: 0;

  text-align: left;

  color: $greyLocal;

  font-size: 14px;
  line-height: 16px;

  &_disabled {
    pointer-events: none;
  }
}

.alert {
  white-space: pre-wrap;
  pointer-events: none;

  color: rgba(255, 255, 255, 0.6);
}

.notify {
  color: $base0;

  font-size: 12px;
  line-height: 14px;
}

.dot {
  margin: 10px auto;

  &_green {
    background: var(--service-sussess);
  }

  &_red {
    background: var(--service-error);
  }

  &_blue {
    background: var(--service-info);
  }
}

.list {
  padding: 10px 0;

  border-radius: 5px;
  background-color: $grey;
}

.item {
  position: relative;

  padding: 10px;

  transition: all 0.2s ease-in-out;

  color: $base0;

  line-height: 14px;

  &:hover {
    background-color: $base8;
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 3px;

    content: '';
    transition: all 0.2s ease-in-out;

    opacity: 0;
    background-color: $base0;
  }

  &:hover::before {
    opacity: 1;
  }
}
