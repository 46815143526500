@import '~@/styles/variables.module';

.graphic_table tr,
.graphic_table tr th:not(:last-child) {
  border: none;
}

.graphic_table tr td {
  padding: 13px 10px;
}

.graphic_table thead tr th {
  z-index: 100;

  padding: 0 0 11px 5px;

  color: var(--txt_1);
  background: var(--bg-widget);

  font-size: 11px;
  line-height: 13px;
}

.graphic_table td {
  background: var(--surface_01);
}

.graphic_table thead tr th:first-child {
  padding-left: 0;
}

.graphic_table thead tr th:last-child {
  padding-right: 20px;
}

.graphic_table svg {
  fill: var(--icon_default_normal);
}
.graphic_table svg:hover {
  fill: var(--icon_primary_normal);
}


.graphic_table tbody {
  position: relative;
}

.graphic_table tbody::after {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  content: '';

  border-radius: 5px;
  background-color: var(--surface_01);
}

.graphic_table tbody tr {
  z-index: 10;
}

.graphic_table tbody tr:not(:last-child) td::after {
  position: absolute;
  right: 17px;
  bottom: 0;
  left: 17px;

  height: 1px;

  content: '';

  border-bottom: 1px solid var(--stroke-separator);
}

.graphic_table tr th {
  background-color: transparent;
}

.graphic_table tr td:first-child,
.graphic_table tr td:last-child {
  padding-right: 20px;
  padding-left: 20px;

  border: none;
}

.graphic_table tbody tr td:not(:first-child) {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}
