@import '~@/styles/variables.module';

.container {
  border-bottom: 1px solid var(--stroke-default);

  :global(.slct__control) {
    box-sizing: border-box;
    min-height: 35px;

    border: none;
    border-color: $base2;
    border-radius: initial;
    background: transparent;
    box-shadow: none;
  }

  :global(.scroll__selected) {
    max-height: 150px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bg-widget);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--surface_06);

      &:hover {
        background: var(--primary_hover);
      }
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
    }
  }

  :global(.slct__placeholder) {
    color: var(--txt_3);
  }

  :global(.slct__value-container) {
    padding: 0 8px;
  }

  :global(.slct__control:hover) {
    border-color: var(--txt_1);
  }

  :global(.slct__indicator-separator) {
    background-color: var(--stroke-separator);
  }

  :global(.slct__control--is-focused),
  :global(.slct__control--is-focused:hover) {
    border-color: var(--txt_1);
  }

  :global(.slct__single-value) {
    color: var(--txt_1);
  }

  :global(.slct__clear-indicator) {
    padding: 0;
  }

  :global(.slct__clear-indicator svg) {
    width: 24px;
    height: 24px;
  }

  :global(.slct__menu) {
    color: var(--txt_1);
    border-radius: 5px;
    background: var(--bg-widget);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08), 0 10px 18px rgba(0, 0, 0, 0.12);
  }
  :global(.slct__menu-list) {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bg-widget);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--surface_06);

      &:hover {
        background: var(--primary_hover);
      }
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
    }
  }

  :global(.isMulti) {
    display: flex;

    background-color: transparent;

    align-items: center;
  }

  :global(.slct__option--is-focused) {
    cursor: pointer;
  }

  :global(.slct__option--is-selected) {
    color: var(--txt_1);
    background: var(--surface_01);
  }

  :global(.css-1n7v3ny-option) {
    background: none;
  }

  :global(.slct__option--is-focused:hover) {
    background: var(--surface_02);
  }

  :global(.slct__option--is-selected:not(.isMulti)) {
    position: relative;

    //background: var(--surface_02);
  }

  :global(.slct__option:active) {
    background: var(--surface_02);
  }

  :global(.slct__multi-value) {
    background: var(--surface_02);
  }

  :global(.slct__multi-value__label),
  :global(.slct__input) {
    color: var(--txt_1);
  }

  :global(.slct__multi-value__remove) {
    cursor: pointer;
    transition: color 0.3s ease-in-out;

    color: var(--secondary-normal);
  }

  :global(.slct__multi-value__remove:hover) {
    color: var(--service-error);
    background-color: transparent;
  }
}

.container_error {
  :global(.slct__control) {
    border-color: var(--service-error);;
  }
}

:global(.track-vertical) {
  top: 2px;
  right: 2px;
  bottom: 2px;

  border-radius: 3px;
  background: rgba(255, 255, 255, 0.111);

  .thumb-vertical {
    position: relative;

    display: block;

    width: 100%;

    cursor: pointer;

    border-radius: inherit;
    background-color: rgba(123, 154, 255, 0.418); // changed the color
  }
}
