@import '~@/styles/variables.module';

.container,
.text {
  border-radius: 5px;
  background: var(--surface_01);
}

.value {
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}

.text {
  height: 124px;

  text-align: center;

  font-size: 16px;
  font-weight: 500;
  line-height: 26px;

  color: var(--txt_1);
}
