@import '~@/styles/variables.module';

.wrapper {
  overflow: hidden;

  width: 340px;

  user-select: none;
  filter: var(--shadow_2);
  border-radius: 5px;
  background: var(--bg-widget);
}

.header {
  background: var(--bg-w-head);
}

.header_year {
  color: var(--txt_widget);

  font-size: 16px;
  line-height: 120%;
}

.header_year:hover {
  cursor: pointer;

  opacity: 0.6;
}

.btn_year {
  cursor: pointer;

  color: var(--txt_widget);
  border: none;
  background-color: transparent;
}

.btn_year:hover {
  opacity: 0.6;
}

.wrap_month {
  box-sizing: border-box;
  max-width: 384px;
}

.btn_month {
  display: flex;

  width: calc(100 / 3) * 1%;
  height: 45px;

  cursor: pointer;

  color: var(--txt_1);
  border: none;
  border-radius: 5px;
  background-color: transparent;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 20px;

  align-items: center;
  justify-content: center;

  &:not(:disabled):not(.btn_month_active):hover {
    background: var(--surface_01);
  }
}

.btn_month_active {
  color: var(--on_primary);
  background: var(--primary_active);
}

.btn_year:disabled {
  cursor: not-allowed;

  opacity: 0.4;
  color: var(--txt_widget);
}

.btn_month:disabled {
  cursor: not-allowed;

  opacity: 0.4;
}
