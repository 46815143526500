@import '~@/styles/variables.module';

.title {
  color: var(--txt_1);

  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.title:hover {
  cursor: pointer;
}

.panel {
  position: relative;
}

.inner {
  padding: 15px;

  list-style-type: none;

  border-radius: 5px;
  background: var(--bg);
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item:nth-last-child(n+2) {
  padding-bottom: 10px;
}

.name {
  margin-right: 15px;

  color: var(--txt_2);
  max-width: 165px;
  font-size: 16px;
  line-height: 136%;
}

.text {
  color: var(--txt_1);
  font-size: 14px;
  line-height: 16px;
}

.wrapper {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 136%;
  gap: 5px;
}

.tooltip {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background-color: var(--surface_05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 10px 18px rgba(0, 0, 0, 0.12);
  padding: 4px 8px;
  border-radius: 5px;
  backdrop-filter: blur(4px);
}

.iconColor:hover {
  cursor: pointer;
}