@import './variables.module';

:root {
  --transition: all 0.2s ease-in-out;

  --box-shadow: 0 4px 20px rgba(0,0,0,0.2);

  --base0: #{$base0};
  --base0-medium: #{rgba($base0, 0.5)};
  --base1: #{$base1};
  --base2: #{$base2};
  --base3: #{$base3};
  --base4: #{$base4};
  --base4-thick: #{rgba($base4, 0.9)};
  --base5: #{$base5};
  --base6: #{$base6};
  --base6-slick: #{rgba($base6, 0.2)};
  --base7: #{$base7};
  --base8: #{$base8};
  --base8-slick: #{rgba($base8, 0.2)};
  --base9: #{$base9};
  --base10: #{$base10};
  --base11: #{$base11};
  --base12: #{$base12};
  --grey: #{$grey};
  --grey-hard: #{rgba($grey, 0.8)};
  --blue: #{$main};
  --blue-light2: #{$blue-light2};
  --green: #{$success};
  --green-dark: #{$success-dark};
  --green-slick: #{rgba($success, 0.3)};
  --red: #{$active};
  --red-second: #{$active-second};
  --red-dark: #{$active-dark};
  --red-slick: #{rgba($active, 0.2)};
  --purple: #{$purple};
  --pink: #{$pink};
  --orange: #{$warning};
  --yellow: #{$yellow};
  --blue-transparent: #{rgba($main, 0.3)};
  --blackout: rgba(31, 40, 57, 0.9);
  --base2-04: #{rgba($base2, 0.4)};
  --transparent: transparent;
  /* новые цвета */
  --service-sussess: #0AD1A3;
  --service-sussess_rgb: 10, 209, 163;
  --service-warning: #F3D55E;
  --service-warning_rgb: 243, 213, 94;
  --service-error: #FA4B4B;
  --service-error_rgb: 250, 75, 75;
  --service-total: #00A6A6;
  //--service-total_rgb: 250, 75, 75;
  --service-info: #00B5EA;
  --service-info_rgb: 0, 181, 234;
  --service-purple_rgb: 78, 88, 171;
  --service-purple: #4E58AB;
  --service-onSussess: #fff;
  --service-onError: #fff;
  --add-txt_1: #FA4B4B;
  --add-txt_2: #F3D55E;
  --add-txt_3: #0AD1A3;
  --add-txt_4: #4F58AB;
  --add-txt_5: #FF7F24;
  --add-txt_: #4F58AB;
  --add-ui_1: #FA4B4B;
  --add-ui_1_rgb: 250, 75, 75;
  --add-ui_2: #F3D55E;
  --add-ui_3: #0AD1A3;
  --add-ui_3_rgb: 10, 209, 163;
  --add-ui_4: #4F58AB;
  --add-ui_5: #FF7F24;
  --add-ui_: #4F58AB;
}

body {
  overflow-x: hidden;

  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
}

html,
body,
#root {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.disabledElement {
  pointer-events: none;
}

