@import '~@/styles/variables.module';

.date {
  min-width: 200px;
}

.wrapper {
  padding-bottom: 250px;

  justify-content: space-between;
}

.wrap_table {
  overflow: hidden;

  border: 1px solid $base2;
  border-radius: 5px;
}

.table {
  width: 100%;
}

.row_header {
  background-color: $base6;
}

.row {
  border-top: 1px solid $base2;
}

.cell {
  padding: 10px 5px;

  text-align: center;

  &:not(:last-child) {
    border-right: 1px solid $base2;
  }
}

.select {
  max-width: 250px;
}
