.bar_wrap {
  opacity: 0.6;

  fill: #1f2839;
}

.bar__plan {
  cursor: pointer;

  fill: #00b5ea;
}

.bar__current {
  cursor: pointer;

  fill: #04a881;
}

.axis--x path {
  display: none;
}

.axis > .domain {
  stroke: none;
}

.tick > line {
  stroke: none;
}

.axis .grid-line {
  stroke: var(--secondary-normal);
  shape-rendering: crispedges;
}

.sales__current {
  text-align: right;

  color: #04a881;

  font-family: Roboto;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
}

.ConnectedServices__chart {
  width: 100%;
  height: 100%;
}

.AppAddServices__chart {
  width: 100%;
  height: 100%;
}

.AppBasicServices__chart {
  width: 100%;
  height: 100%;
}

#Sales .filter {
  display: flex;

  padding: 0 10px;

  justify-content: space-between;
}

.filter__box_sales {
  display: flex;
  flex-direction: column;
}

.Sales__chart {
  width: 100%;
  height: 100%;
}
