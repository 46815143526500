@import '~@/styles/variables.module';

.label {
  color: inherit;

  font-size: 16px;
  line-height: 19px;
}

.input {
  font-size: 22px;
  line-height: 26px;
}

.best {
  color: var(--service-sussess);
}

.worst {
  color: var(--service-error);
}

