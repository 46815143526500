.activeLang {
    width: 30px;
  
    cursor: pointer;
    border-radius: 5px;
    color: var(--txt_1);
    border: none;
    background-color: transparent;
    &:hover {
      background: var(--surface_03);
    }
  }