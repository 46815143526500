.descTabTitle {
  color: var(--txt_1);

  font-size: 30px;
  line-height: 35px;
}

.wrapperMiniWidgets {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: var(--bg-widget);
  min-height: 105px;
}
