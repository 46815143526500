@import '~@/styles/variables.module';

.container {
  //padding: 5px 10px;
  padding: 7px 10px;

  //border: 2px solid var(--primary);
  border-radius: 4px;
  background: var(--surface_02);

  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: var(--txt_2);
}

.clock_wrapper {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.animation_clock {
  animation: rotate-clock 9000ms linear infinite normal forwards
}

@keyframes rotate-clock {
  0% {
    transform: translate(12.0215px, 12px) rotate(0deg)
  }
  100% {
    transform: translate(12.0215px, 12px) rotate(359deg)
  }
}
