@import '~@/styles/variables.module';

.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.legend {
  flex: 0 0 auto;
}

.dot {
  width: 7px;
  height: 7px;

  border-radius: 100%;
}

.grid-line {
  stroke: $bg-grid-line;
  shape-rendering: crispedges;
  stroke-opacity: 0.2;
}
