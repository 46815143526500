@import '~@/styles/variables.module';

.bio {
  display: flex;
  flex-direction: column;

  text-align: center;

  font-size: 16px;

  justify-content: center;
}

.bio_wrapper {
  overflow: hidden;
  transition: all .3s ease-in-out;
}

.name {
  margin: 0 auto;

  font-weight: bold;

  text-align: center;

  color: var(--txt_1);

  line-height: 28px;
}

.text {
  color: var(--txt_2);

  font-weight: 400;
  line-height: 26px;
}

.link {
  transition: $transition;    

  &:hover {    
    text-decoration: none;
  }
}