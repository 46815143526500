@import '~@/styles/variables.module';

.label {
  position: relative;

  display: block;

  box-sizing: border-box;
  width: 43px;
  height: 21px;
  padding: 0 4px;

  cursor: pointer;
  transition: background 0.2s ease;
  
  border-radius: 100px;
  background: $base10;

  flex-shrink: 0;

  &::after {
    position: absolute;
    top: 52%;
    left: 45%;

    width: 15px;
    height: 15px;

    content: '';
    transition: transform 0.4s ease;
    transform: translate(-100%, -50%);

    border-radius: 100px;
    background: $base0;
  }
}

.is_active {
  background: var(--primary);

  &::after {
    transform: translate(0, -50%);
    left: 55%;
  }
}

.input {
  display: none;
}
