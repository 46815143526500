.title {
  color: var(--txt_1);

  font-size: 16px;
  line-height: 19px;
}

.btn {
  display: flex;

  padding: 0;

  transition: border 0.2s ease-in-out;

  border: 2px solid transparent;
  border-radius: 5px;
  background: transparent;

  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &_action {
    border: 2px solid ;
    border-color: var(--primary);
  }
}

.icon {
  fill: var(--secondary-normal);

  &:hover {
    fill: var(--secondary-hover);
  }

  &_active {
    fill: var(--secondary-active);
  }
}
