.container {
  width: 100%;
  height: 100%;
}
.containerChart {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  user-select: none;
}
