.OperationalReport table td {
  text-align: center;
  vertical-align: middle;
}

.OperationalReport .salesTable__row td {
  text-align: center;
  vertical-align: middle;

  border-right: 1px solid var(--stroke-default);
  border-bottom: 1px solid var(--stroke-default);
  background: #1f2839;

  font-size: 14px;
}

.OperationalReport-buttons__wrapper {
  max-width: 260px;
  padding: 15px;
}

.OperationalReport__export {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-end;

  width: 100%;
  max-width: 350px;
  margin-left: auto;
  padding: 15px;
}

.OperationalReport__export button,
.OperationalReport__export a {
  width: 47%;
}

.OperationalReport__export button:last-child {
  margin-left: 20px;
}

.OperationalReport-currentperiod {
  margin-top: 15px;
}

.OperationalReport .salesTable__scroll {
  max-height: 700px;
}

.OperationalReport .salesTable__scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;

  background: var(--bg);
}

.OperationalReport .salesTable__scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--surface_06);
}
.OperationalReport .salesTable__scroll::-webkit-scrollbar-thumb:hover {
  background: var(--primary_hover);
}

.OperationalReport__subTh-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  line-height: 32px;
}

.sortButton {
  position: relative;

  display: inline-block;

  margin: 0;
  margin-left: 10px;
  padding: 0 5px;

  cursor: pointer;

  border: none;
  /* border-left  : 1px solid hsla(0,0%,56.5%,.4); */
  outline: none;
  background-color: transparent;

  line-height: 32px;
}

.sortButton .icon {
  transform: translateY(5px);
}

.sortButton .icon.inverse {
  transform: rotateX(180deg) translateY(-5px);
}

.sortButton .icon {
  fill: #FFFFFF;
}
.sortButton:hover .icon {
  fill: var(--primary);
}

.sortButton::after {
  position: absolute;
  top: 0;
  right: 100%;

  width: 1px;
  height: calc(100% + 12px);

  content: '';
  transform: translate(0, -6px);

  background: #6d727a;
}

.OperationalReport thead .salesTable__row td,
.OperationalReport thead .salesTable__row th {
  /*background: #565e6c;*/
  background: var(--table_header);
  color: var(--txt_widget);
}

.OperationalReport tfoot .salesTable__row td {
  /*background: #1a4b5b;*/
  background: var(--table_total_default);
}

.OperationalReport .OR_table-allRow td {
  /*background: #1a4b5b;*/
  background: var(--table_total_default);
}

.OperationalReport .cell-red {
  /*background: rgba(96, 47, 59, 0.5) !important;*/
  background: var(--table_cell-red_default);
}

.OperationalReport .cell-green {
  background: var(--table_cell-green_default);
}

.OperationalReport .cell-lightgreen {
  background: var(--table_cell-lightgreen_default);
}

.OperationalReport .cell-blue {
  background: var(--table_cell-blue_default);
}

.OperationalReport .cell-darkblue {
  background: var(--table_cell-darkblue_default)
}

.OperationalReport .cell-yellow {
  background: var(--table_cell-yellow_default);
}

.OperationalReport .cell-black {
  background: var(--table_cell-black_default);
}

.OperationalReport .innerTable4OR,
.OperationalReport .innerTable4OR td,
.OperationalReport .innerTable4OR tr {
  position: relative;
  z-index: 1;

  text-align: center;

  border: none;
}

/* alex changes 1.6.20 */
.OperationalReport-buttons__wrapper {
  /* !!! исправил чужой код !!! */
  max-width: max-content;
}

.OR__header {
  display: flex;
  flex-direction: row;
}

.total_datum {
  padding: 15px;

  line-height: 40px;
}

.total_datum label {
  display: flex;
}

.total_datum span {
  margin-left: 10px;

  font-size: 16px;
  line-height: 19px;
}

.sortButton_active svg{
  fill: #00B5EA;
}

.OperationalReport .salesTable table tbody td:first-child{
  padding: 5px;
}

.total_datum-text{
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}
.total-data{
  line-height: 1.5;
}
.check-data-range{
  line-height: 2;
}
