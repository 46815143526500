@import '~@/styles/variables.module';

.text {
  color: $base2;
}

.date {
  font-size: 16px;
  flex-shrink: 0;
}

.time {
  font-size: 18px;
  flex-shrink: 0;
}
