.breadCrumsItem {
  border: 1px solid var( --txt_tertiary);
  border-radius: 30px;
  background-color: transparent;
}

.breadCrumsItem:hover {
  transition: background-color 0.3s ease-in-out;

  background-color: var(--txt_tertiary);
}

.name,
.style {
  box-sizing: border-box;
  padding: 8px 8px 8px 16px;

  font-weight: 400;
  line-height: 24px;
}

.iconCircle {
  cursor: pointer;

  fill: var(--icon_secondary_normal);
}
