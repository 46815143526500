.UsersManagement .import-data__buttons,
.UsersManagement .import-data__filter-choose {
  width: max-content;
  margin-bottom: 20px;
}
.UsersManagement .import-data__buttons button,
.UsersManagement .import-data__filter-choose button {
  width: auto;
  margin-right: 20px;
}
.UsersManagement .import-data__buttons button:last-child,
.UsersManagement .import-data__filter-choose button:last-child {
  margin-right: 0;
}

.UsersManagement .table-wrapper {
  overflow-x: auto;

  box-sizing: border-box;
  width: 100%;
  height: 600px;

  border: 1px solid rgba(144, 144, 144, 0.4);
  border-radius: 5px;
}
.UsersManagement th {
  box-sizing: border-box;

  vertical-align: middle;

  border: 1px solid hsla(0, 0%, 56.5%, 0.4);
  border-right: 1px solid rgba(144, 144, 144, 0.4);
  border-bottom: 1px solid rgba(144, 144, 144, 0.4);
  background: var(--base6);

  font-size: 14px;
}
.UsersManagement th:last-child {
  border-right: none;
}
.UsersManagement td {
  position: relative;

  box-sizing: border-box;
  padding: 15px 5px;

  text-align: center;
  vertical-align: middle;

  border-right: 1px solid rgba(144, 144, 144, 0.4);
  border-bottom: 1px solid rgba(144, 144, 144, 0.4);
}
.UsersManagement td:last-child {
  border-right: none;
}
.UsersManagement .lightRow td {
  background: rgba(95, 101, 115, 0.2)
}
.UsersManagement .innerTable td {
  border: none;
  background: transparent;
}
.UsersManagement .users-management-table {
  width: 100%;
}
.UsersManagement .innerTable {
  margin: auto;
}
/* .UsersManagement .table-container {
  width: 500px;
  overflow: hidden;
} */
.UsersManagement h2 {
  margin-bottom: 20px;

  font-size: 20px;
}

.UsersManagement .table-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 30px;

  background: transparent;
}

.UsersManagement .table-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: transparent;
}

.UsersManagement .table-wrapper:hover::-webkit-scrollbar {
  background: var(--base4);
}

.UsersManagement .table-wrapper:hover::-webkit-scrollbar-thumb {
  background: var(--blue);
}
.UsersManagement .uCanOpenRow {
  cursor: pointer;
}
.UsersManagement .th-sortbutton {
  width: 45px;
  padding: 15px 0;

  border: none;
  border-left: 1px solid rgba(144, 144, 144, 0.4);
  background: transparent;

  flex-shrink: 0;
}
.UsersManagement .th-sortbutton:hover {
  cursor: pointer;
}
.UsersManagement .th-wrapper {
  display: flex;

  align-items: center;
  justify-content: center;
}
.UsersManagement .th-text {
  width: 100%;
}

.UsersManagement__wrapper {
  border-radius: 5px;
  background: var(--base4);
}

/* .UsersManagement .widget__content{
  overflow: visible;
}
.UsersManagement table .filter-second__label{
  justify-content: center;

  padding: 0;
}
.UsersManagement .sortedParam svg {
  fill: var(--blue);
}
.UsersManagement .sortedParam.sortReverse {
  transform: rotate(180deg);
}



.users-management-table .link-elem{
  cursor: pointer;

  color: var(--blue);
}

.users-management-table .link-elem:hover{
  text-decoration: underline;
}*/
