@import '~@/styles/variables.module';

.wrapper {
  //border: 2px solid var(--primary);
  border-radius: 5px;
  background: var(--surface_02);
  color: var(--txt_2);
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.value {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.1;
}

.caption {
  font-size: 16px;
}
