@import '~@/styles/variables.module';

.label {
  text-align: center;

  font-size: 26px;
  line-height: 32px;
}

.input:-webkit-autofill {
  box-shadow: inset 0 0 0 50px $base4;
  -webkit-text-fill-color: $base0;
}

.btnForm {
  color: #fff;
  border: 2px solid $main;
  background: $main;

  &:hover {
    border: 2px solid $main;
    background: transparent;
  }
}
