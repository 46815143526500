@import '~@/styles/variables.module';

.button {
  display: inline-flex;
  flex-direction: column;

  box-sizing: border-box;
  min-width: 148px;
  min-height: 60px;
  padding: 10px;

  cursor: pointer;
  transition: $transition;
  text-align: center;
  text-decoration: none;

  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  outline: none;
  background: transparent;

  align-items: center;
  justify-content: center;

  &.is_active {
    background: var(--primary_active);
    color: var(--on_primary);
    cursor: default;
  }

  &_large {
    min-width: 200px;
    padding: 20px 10px;
  }

  &_medium {
    min-width: 160px;
    min-height: 80px;
    padding: 10px 10px;
  }
}

.label {
  font-size: 13px;
  line-height: 20px;
}
