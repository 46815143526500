@import '~@/styles/variables.module';

.tooltip {
  width: max-content;
  padding: 7px;

  color: #fff;
  border-radius: 5px;
  background: var(--surface_05);
  box-shadow: $box-shadow;
}
