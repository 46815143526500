@import '~@/styles/variables.module';

.container,
.wrapper {
  height: 80%;
}

.wrapDot {
  margin-top: 7px;
}

.dot {
  width: 7px;
  height: 7px;

  border-radius: 100%;
}

.value {
  font-weight: 700;
}

.title {
  font-size: 25px;
  font-weight: bold;
  line-height: 120%;

  text-anchor: middle;
}

.subTitle {
  font-size: 14px;
  line-height: 20px;

  fill: var(--txt_2);
  text-anchor: middle;
}

