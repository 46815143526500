@import '~@/styles/variables.module';

.container {
  width: 100%;
}

.container_disabled {
  opacity: 0.5;
}

.label {
  display: block;

  transition: $transition;

  color: var(--txt_3);

  font-size: 12px;
  line-height: 20px;
}

.label_focused {
  color: var(--txt_3);
}

.label_error {
  animation: v-shake 0.6s cubic-bezier(0.25,0.8,0.5,1);

  color: var(--service-error);
}

.details {
  min-height: 14px;
  margin-top: 2px;

  color: var(--txt_3);

  font-size: 12px;
  line-height: calc(14 / 12);
}

.details_error {
  color: var(--service-error);
}
