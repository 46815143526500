@import '~@/styles/variables.module';

.wrapper {
  box-sizing: border-box;
  max-width: 300px;
  padding: 10px ;

  color: #fff;
  border-radius: 5px;
  background-color: var(--surface_05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  font-size: 12px;
  line-height: 16px;

  backdrop-filter: blur(7px);
}

.iconColor {
  color: var(--icon_on_widget_header_normal);

  &:hover {
    //color: var(--icon_on_widget_header_hover);
    opacity: .6;
  }
}
.iconColorNew {
  color: var(--secondary-normal);
  //opacity: .8;

  &:hover {
    //color: var(--icon_on_widget_header_hover);
    opacity: .6;
  }
}
