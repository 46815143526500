@import '~@/styles/variables.module';

.wrapper {
  display: flex;
}

.label {
  display: flex;

  cursor: pointer;
  user-select: none;

  align-items: center;
}

.label_error {
  color: $error;
}
