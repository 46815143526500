@import '~@/styles/variables.module';


.draggable {
  cursor: grabbing;
}

.relative {
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  height: 100%;

  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);

  align-items: stretch;
  justify-content: stretch;

  &:focus-within {
    position: relative;
    z-index: 3;
  }
}

.header {
  display: flex;

  box-sizing: border-box;
  width: 100%;
  height: 55px;
  padding: 0 10px;

  pointer-events: auto;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: $base9;

  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}

.title_box {
  display: flex;
  flex-direction: column;

  font-size: 14px;

  align-items: flex-start;
}

.title {
  color: var(--txt_widget);

  font-size: 16px;
  line-height: 19px;
}
