@import '~@/styles/variables.module';

.button {
  padding: 0;

  cursor: pointer;
  transition: $transition;

  color: $base2;
  border: none;
  outline: none;
  background: transparent;

  &:not(:disabled):hover {
    opacity: .6;
  }
}
