.pagination {
  display: flex;
  width: max-content;

  align-items: center;
  justify-content: center;
}

.pagination_buttons {
  display: flex;
  padding: 0 7px;
}
