@import '~@/styles/variables.module';

.main {
  display: inline-flex;

  box-sizing: border-box;
  min-width: 172px;
  height: 42px;
  padding: 8px 20px;

  cursor: pointer;
  transition: $transition;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
 
  border: none;
  border-radius: 5px;
  outline: none;  

  font-family: 'Roboto';
  font-size: 14px;
  line-height: 24px;

  align-items: center;
  justify-content: center;
  flex-shrink: 0;  

  &:disabled {
    cursor: not-allowed;

    opacity: 0.6;
  }
}

.block {
  display: flex;

  width: 100%;
}

.small {
  min-width: 130px;
  height: 100%;
}

.xsmall {
  min-width: 90px;
  height: 40px;
}

.auto_width {
  min-width: auto;
}

.outline {
 
  &:hover:not(:disabled):not(.loading) {
    background-color: $main;
  }
}


.active {  
  background: $active;
}

.loading {
  cursor: default;
}
