.title {
  color: var(--txt_1);

  font-size: 12px;
}

.legend__value {
  color: var(--txt_1);
  font-weight: 700;
  margin-left: auto;
  max-width: 240px;
}

.legend__centered {
  margin: 0 auto;
  max-width: 200px;
}

.verLegWrap{
  width: 100%;
}