[theme='light_default'] {
  --bg: #e9eaec;
  --app-color: #000;
  --bg-w-head: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), var(--primary);
  --bg-widget: #fff;
  --bg_settings_menu: linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0.86) 100%);
  /* для aside*/
  --bg-menu_01: #293142;
  --bg-menu_01_open: linear-gradient(90deg, #293142 24.17%, rgba(41, 49, 66, 0.85) 100%);
  --menu_01_active: var(--primary);
  /* UserPanel*/
  --bg-menu_02: #ffffff;

  --surface_01: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #293142;
  --surface_02: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #293142;
  --surface_02_rgb: rgba(212, 214, 214, 1);
  --surface_03: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), #293142;
  --surface_04: #E9EAEC;
  --surface_05: rgba(100, 110, 131, 0.9);
  --surface_06: rgba(153, 164, 179, 1);
  --speedo-arrow: #A5A9AF;
   /*для всех светлых тем */
   --stroke-default: rgba(0, 0, 0, 0.2);
   --stroke-active: rgba(0, 0, 0, 0.6);
   --stroke-separator: rgba(0, 0, 0, 0.4);
   --secondary-normal: rgba(0, 0, 0, 0.2);
   --secondary-onSecondary: rgba(0, 0, 0, 0.8);
   --secondary-hover: rgba(0, 0, 0, 0.3);
   --secondary-active: rgba(0, 0, 0, 0.1);
  /* для шрифтов */
  --txt_1: rgba(0, 0, 0, 0.8);
  --txt_2: rgba(0, 0, 0, 0.6);
  --txt_3: rgba(0, 0, 0, 0.4);
  --txt_4: rgba(0, 0, 0, 0.3);
  --txt_5: #66768E;
  --txt_link: #44A5FF;
  --txt_link_hover: #ffffff;
  --txt_widget: #ffffff;
  --txt_tertiary: #f16B52;
  --txt_primary: #0A6CF5;
  /* для маленьких виджетов*/
  --bg-miniwidget: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #00B5EA;
  --miniwidget-stroke: #00b5ea;
  --bg-mini: #FFFFFF;
  /* для иконок*/
  --icon_primary_normal: var(--primary);
  --icon_primary_hover: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--primary);
  --icon_primary_active: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--primary);
  --icon_primary_disable: var(--primary);

  --icon_secondary_normal: var(--secondary-normal);
  --icon_secondary_hover: var(--secondary-hover);
  --icon_secondary_active: var(--secondary-active);
  --icon_secondary_disable: var(--secondary-normal);

  --icon_tertiary_normal: var(--tertiary);
  --icon_tertiary_hover: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--tertiary);
  --icon_tertiary_active: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--tertiary);
  --icon_tertiary_disable: var(--tertiary);

  --icon_on_primary: #ffffff;
  --icon_on_secondary: #000000;
  --icon_on_tertiary: #ffffff;

  --icon_on_menu_normal: rgba(255, 255, 255, 0.6);
  --icon_on_menu_hover: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--primary);
  --icon_on_menu_active: var(--primary);
  --icon_on_menu_disable: #ffffff;

  --icon_on_widget_header_normal: #ffffff;
  --icon_on_widget_header_hover: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--primary);
  --icon_on_widget_header_active: #ffffff;
  --icon_on_widget_header_disable: rgba(#ffffff, 0.4);

  --icon_default_normal: rgba(0, 0, 0, 0.2);
  --icon_default_hover: rgba(0, 0, 0, 0.2);
  --icon_default_active: rgba(0, 0, 0, 0.2);
  --icon_default_disable: rgba(0, 0, 0, 0.2);

  /* для кнопки выбора арма */
  --border_color: rgba(0, 0, 0, 0.2);

  /* для таблиц */

  --table_header: linear-gradient(0deg, #828A98, #828A98), #99A4B3;;
  --table_default: #FFFFFF;
  --table_default_base: #293142;
  --table_hover: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_select: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  --table_error_default: linear-gradient(0deg, rgba(250, 75, 75, 0.1), rgba(250, 75, 75, 0.1)), var(--table_default);
  --table_error_hover: linear-gradient(0deg, rgba(250, 75, 75, 0.1), rgba(250, 75, 75, 0.1)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_error_select: linear-gradient(0deg, rgba(250, 75, 75, 0.1), rgba(250, 75, 75, 0.1)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  --table_warning_default: linear-gradient(0deg, rgba(243, 213, 94, 0.5), rgba(243, 213, 94, 0.5)), #FFFFFF;
  --table_warning_hover: linear-gradient(0deg, rgba(243, 213, 94, 0.5), rgba(243, 213, 94, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_warning_select: linear-gradient(0deg, rgba(243, 213, 94, 0.5), rgba(243, 213, 94, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  --table_success_default: linear-gradient(0deg, rgba(10, 209, 163, 0.1), rgba(10, 209, 163, 0.1)), var(--table_default);
  --table_success_hover: linear-gradient(0deg, rgba(10, 209, 163, 0.1), rgba(10, 209, 163, 0.1)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_success_select: linear-gradient(0deg, rgba(10, 209, 163, 0.1), rgba(10, 209, 163, 0.1)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  --table_total_default: linear-gradient(0deg, rgba(0, 166, 166, 0.4), rgba(0, 166, 166, 0.4)), var(--table_default);
  --table_total_hover: linear-gradient(0deg, rgba(0, 166, 166, 0.4), rgba(0, 166, 166, 0.4)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_total_select: linear-gradient(0deg, rgba(0, 166, 166, 0.4), rgba(0, 166, 166, 0.4)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);
  /* для теней */
  --shadow_2:drop-shadow(0px -10px 20px rgba(0, 0, 0, 0.08)) drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.08));

  /* цвета ячеек для таблиц */

  --table_cell-green_default:  linear-gradient(0deg, rgba(23, 74, 74, 0.5), rgba(23, 74, 74, 0.5)), var(--table_default);
  --table_cell-green_hover: linear-gradient(0deg, rgba(23, 74, 74, 0.5), rgba(23, 74, 74, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_cell-green_select: linear-gradient(0deg, rgba(23, 74, 74, 0.5), rgba(23, 74, 74, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  --table_cell-lightgreen_default: linear-gradient(0deg, rgba(37, 108, 87, 0.5), rgba(37, 108, 87, 0.5)), var(--table_default);
  --table_cell-lightgreen_hover: linear-gradient(0deg, rgba(37, 108, 87, 0.5), rgba(37, 108, 87, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_cell-lightgreen_select: linear-gradient(0deg, rgba(37, 108, 87, 0.5), rgba(37, 108, 87, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  --table_cell-blue_default: linear-gradient(0deg, rgba(26, 75, 91, 0.5), rgba(26, 75, 91, 0.5)), var(--table_default);
  --table_cell-blue_hover: linear-gradient(0deg, rgba(26, 75, 91, 0.5), rgba(26, 75, 91, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_cell-blue_select: linear-gradient(0deg, rgba(26, 75, 91, 0.5), rgba(26, 75, 91, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  --table_cell-darkblue_default: linear-gradient(0deg, rgba(0, 166, 166, 0.4), rgba(0, 166, 166, 0.4)), var(--table_default);
  --table_cell-darkblue_hover: linear-gradient(0deg, rgba(0, 166, 166, 0.4), rgba(0, 166, 166, 0.4)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_cell-darkblue_select: linear-gradient(0deg, rgba(0, 166, 166, 0.4), rgba(0, 166, 166, 0.4)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  --table_cell-yellow_default: linear-gradient(0deg, rgba(182, 144, 6, 0.5), rgba(182, 144, 6, 0.5)), var(--table_default);
  --table_cell-yellow_hover: linear-gradient(0deg, rgba(182, 144, 6, 0.5), rgba(182, 144, 6, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_cell-yellow_select: linear-gradient(0deg, rgba(182, 144, 6, 0.5), rgba(182, 144, 6, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  --table_cell-black_default: linear-gradient(0deg, rgba(38, 47, 72, 0.5), rgba(38, 47, 72, 0.5)), var(--table_default);
  --table_cell-black_hover: linear-gradient(0deg, rgba(38, 47, 72, 0.5), rgba(38, 47, 72, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_cell-black_select: linear-gradient(0deg, rgba(38, 47, 72, 0.5), rgba(38, 47, 72, 0.5)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  --table_cell-red_default: linear-gradient(0deg, rgba(250, 75, 75, 0.2), rgba(250, 75, 75, 0.2)), var(--table_default);
  --table_cell-red_hover: linear-gradient(0deg, rgba(250, 75, 75, 0.2), rgba(250, 75, 75, 0.2)), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--table_default_base);
  --table_cell-red_select:  linear-gradient(0deg, rgba(250, 75, 75, 0.2), rgba(250, 75, 75, 0.2)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--table_default_base);

  /* цвета для алертов */

  --alert_color-info: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), rgba(var(--service-info_rgb), 0.8);
  --alert_color-warning: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), rgba(var(--service-warning_rgb), 0.8);
  --alert_color-error: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), rgba(var(--service-error_rgb), 0.8);
  --alert_color-success: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), rgba(var(--service-sussess_rgb), 0.8);
  --alert_color-purple: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), rgba(var(--service-purple_rgb), 0.8);
  --alert_color-gray: rgba(100, 110, 131, 0.9);
  --alert_color-gray_border: var(--surface_05);

  --progress-bar-shadow: rgba(0, 0, 0, 0.25);
}
