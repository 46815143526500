@import '~@/styles/variables.module';

.wrapper {
  width: max-content;
}

.btn {
  display: flex;
  align-items: center;

  box-sizing: border-box;
  width: 22px;
  height: 22px;
  padding: 0;

  cursor: pointer;
  transition: color 150ms ease-out;

  color: currentColor;
  border: 1px solid $base6;
  border-radius: 3px;
  outline: none;
  background-color: transparent;

  &:hover {
    color: var(--primary);
    border: 1px solid var(--primary);
  }
}

.btn_small {
  width: 16px;
  height: 16px;
}
