@import '~@/styles/variables.module';

.container {
  border-radius: 100%;
}

.container_info {
  background: $main;
}

.container_warning {
  background: $warning;
}

.container_error {
  background: $error;
}

.container_success {
  background: $success;
}

.container_gray {
  background: $base6;
}
