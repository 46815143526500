@import '~@/styles/variables.module';

.container {
  box-sizing: border-box;
  width: 100%;

  color: inherit;
  border: 0;
  border-radius: 5px;
  outline: 0;
  background: var(--surface_01);
}

.label {
  font-size: 16px;
  color: var(--txt_1);
}

.value {
  color: $yellow;

  font-size: 30px;
  font-weight: 700;
  line-height: 1;
}

.cursor {
  cursor: pointer;
}

.button {
  display: flex;

  padding: 0;

  cursor: pointer;

  color: inherit;
  border: none;
  outline: none;
  background: transparent;
}

.delimeter {
  height: 1px;

  background: var(--surface_01);
}
