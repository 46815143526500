@import '~@/styles/variables.module';

.item {
  box-sizing: border-box;
  width: 100%;

  border: 2px solid $purple;
  border-radius: 5px;
  background: $base9;
}

.header {
  text-align: center;

  color: $base0;

  font-size: 23px;
}

.items_wrap {
  display: flex;

  width: 100%;

  color: $main;

  font-size: 25px;

  justify-content: space-evenly;
}
